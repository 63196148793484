import axios from 'axios'
//获取省市区数据
export function locationSelectQueryList(data: any) {
  return axios.post('/api/mdm/metedata/region/v1/queryList', data)
}
//我的系统及身份认证
export function portalSiteList() {
  return axios.post('/api/mdm/portal/site/v1/portalSiteList')
}
//个人资料接口
export function userMessage() {
  return axios.post('/api/mdm/portal/site/v1/userMessage')
}

//编辑个人资料接口
export function useSave(data: any) {
  return axios.post('/api/mdm/portal/site/v1/useSave', data)
}

//认证企业（组织）列表接口
export function companyList() {
  return axios.post('/api/mdm/portal/site/v1/companyList')
}

//承运商详情接口
export function carrierDetail(data: any) {
  return axios.post('/api/mdm/portal/site/v1/carrierDetail', data)
}
//货主详情接口
export function shipperDetail(data: any) {
  return axios.post('/api/mdm/portal/site/v1/shipperDetail', data)
}
//上传证件图片
export function uploadPrivatePicture(data: any) {
  return axios.post('/api/third-party/v1/bos/upload/private/picture', data)
}

//ocr行驶证识别
export function vehicleLicense(data: any) {
  return axios.post('/api/third-party/v1/ocr/vehicleLicense', data)
}
//ocr营业执照识别
export function businessLicense(data: any) {
  return axios.post('/api/third-party/v1/ocr/businessLicense', data)
}
//ocr道路运输许可证识别
export function roadTransportCert(data: any) {
  return axios.post('/api/third-party/v1/ocr/roadTransportCert', data)
}
//ocr身份证识别
export function ocrIdCard(data: any) {
  return axios.post('/api/third-party/v1/ocr/idCard', data)
}
//ocr驾驶证识别
export function driverLicense(data: any) {
  return axios.post('/api/third-party/v1/ocr/driverLicense', data)
}

/**
 * @description: 获取客服电话
 */
export function dutyList(data: any) {
  return axios.post('/api/sys/v1/app/config/dutyList', data)
}
