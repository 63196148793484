import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default function useLocale() {
  const i18n = useI18n()

  // 当前选择
  const current = computed(() => {
    return i18n.locale.value
  })

  // 变更语言
  const handleSelect = (value: string) => {
    i18n.locale.value = value
    localStorage.setItem('wzc-locale', value)
  }
  return { current, handleSelect }
}
