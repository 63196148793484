<script setup lang="ts">
import router from "@/router";
import Modal from "@arco-design/web-vue/es/modal";
import { useUserStore } from "@/stores/modules/user";
const userStore = useUserStore();
console.log("userStore", userStore.$state.headPortrait);
const personalCenter = () => {
  router.push({ name: "authentication" });
};
const logOut = () => {
  Modal.confirm({
    title: "确定退出？",
    content: "确定要退出当前账号?",
    onOk: () => {
      localStorage.clear();
      router.push({ name: "login" });
    },
  });
};
</script>

<template>
  <li>
    <a-dropdown>
      <a-button class="nav-btn">
        <a-avatar :size="24" style="margin-right: 8px">
          <img
            alt="avatar"
            :src="
              userStore.$state.headPortrait
                ? userStore.$state.headPortrait
                : 'https://p1-arco.byteimg.com/tos-cn-i-uwbnlip3yd/3ee5f13fb09879ecb5185e440cef6eb9.png~tplv-uwbnlip3yd-webp.webp'
            "
          />
        </a-avatar>
        <icon-down />
      </a-button>
      <template #content>
        <a-doption @click="personalCenter">个人中心</a-doption>
        <a-doption @click="logOut">退出登录</a-doption>
      </template>
    </a-dropdown>
  </li>
</template>

<style scoped lang="less"></style>
