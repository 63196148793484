export default {
  'laypage.page': 'Page',
  'laypage.bar': 'Bar',
  'laypage.total': 'Total',
  'laypage.to': 'To',
  'laypage.confirm': 'Confirm',
  //#通用',
  search: 'Search',
  reset: 'Reset',
  export: 'Export',
  year: 'Year',
  month: 'Month',
  date: 'Date',
  edit: 'Edit',
  add: 'Add',
  delete: 'Delete',
  close: 'Close',
  submit: 'Submit',
  cancle: 'Cancel',
  determine: 'Determine',
  select: 'Select',
  selectAll: 'All',
  save: 'Save',
  title: 'Title',
  formopen: 'Open',
  putaway: 'Put Away',
  Auditprompt: 'Being reviewed, please wait patiently',
  //#栏目管理',
  site: 'Site',
  column: 'Column',
  columnName: 'Column Name',
  cloumPla: 'Please enter the column name',
  visitUrl: 'Visit Url',
  visitUrlPla: 'Please enter the access address',
  //#站点设置',
  siteName: 'Site Name',
  systemSet: 'System Set',
  ownerAppQRCode: 'Owner App QRCode',
  ownerAppQRCodeAlt: 'Owner App QRCode',
  uploadOwnerAppQRCode: 'Upload Owner App QRCode',
  driverAppQRCode: 'Driver App QRcode',
  driverAppQRCodeAlt: 'Driver App QR Code',
  uploadDriverAppQRCode: 'Upload Driver App QRCode',
  siteDes: 'Site Describe',
  address: 'Address',
  phone: 'Phone',
  email: 'Email',
  keywords: 'Keywords',
  copyright: 'Copyright',
  recordNo: 'Record No.',
  settingPla: 'Multiple keywords, please use English (,) to separate',
  //#内容管理',
  subcolumn: 'Sub Column',
  coverImg: 'Cover Image',
  uploadImage: 'Upload Image',
  advisetitle1: 'Recommended upload size  270px wide and 175px high',
  advisetitle2: 'Recommended upload size  width 96px height 96px',
  advisetitle3: 'Recommended upload size  430px wide and 330px high',
  advisetitle4: 'Recommended upload size  245px wide and 166px high',
  uploading: 'Uploading',
  titlePla: 'Please enter a title',
  abstract: 'Abstract',
  abstractpla: 'Please enter the article summary',
  content: 'Content',
  //#合作伙伴',
  partners: 'Partners',
  partnerName: 'Partner Name',
  partnerNamePla: 'Please enter the partner name',
  picture: 'Picture',
  reLoad: 'Upload Pictures Again',
  linkUrl: 'Link Address',
  linkUrlPla: 'Click the address to jump to',
  rotationchart: 'Rotation Chart',
  //#轮播图设置',
  pictureTitle: 'Picture Title',
  uploadVideo: 'Upload Video',
  advise5: 'Recommended upload size  1920px wide and 660px high',
  //#增值服务',
  companyName: 'Company Name',
  isdealt: 'Processing',
  name: 'Name',
  dealt: 'Dealt',
  Processed: 'Processed',
  Untreated: 'Untreated',
  //#路线库 路段管理 待审核路线',
  routeName: 'Route Name',
  startaddress: 'Start Address',
  endaddress: 'End Address',
  state: 'State',
  newroute: 'New Route',
  routedetail: 'Route Detail',
  enable: 'Enable',
  disable: 'Disable',
  selfbuiltplatform: 'Self Built Platform',
  ownersharing: 'Owner Sharing',
  carriersharing: 'Carrier Sharing',
  largetruck: 'Large Truck',
  minivans: 'Mini Vans',
  flatbedtruck: 'Flatbed Truck',
  normalroute: 'Normal Route',
  timelimitedroute: 'Time Limited Route',
  avoidanceroute: 'Avoidance Route',
  prohibitedroute: 'Prohibited Route',
  automobiletransportation: 'Automobile Transportation',
  railwaytransportation: 'Railway Transportation',
  airtransport: 'Air Transport',
  shipping: 'Shipping',
  notshared: 'Not Shared',
  applyingforsharing: 'Applying For Sharing',
  applyingforadjustment: 'Applying For Adjustment',
  rejected: 'Rejected',
  passed: 'Passed',
  rescinded: 'Rescinded',
  transportway: 'Transport Way',
  carmodel: 'Car Model',
  openDate: 'OpenDate',
  pressnavigation: 'Press Navigation',
  preferentialadoption: 'Preferential Adoption',
  startOpenDate: 'StartOpenDate',
  terminalOpenDate: 'Terminal OpenDate',
  section: 'Section',
  addsection: 'Add Section',
  serialnumber: 'Serial Number',
  type: 'Type',
  operation: 'Operation',
  routePlan: 'Route Plan',
  deletereason: 'Delete Reason',
  routeinfo: 'Route Info',
  trackname: 'Track Name',
  createRoute: 'Create Route',
  clearwaypoint: 'Clear Passing Point',
  viewtracks: 'View Tracks',
  detail: 'Detail',
  yes: 'Yes',
  no: 'No',
  shared: 'Shared',
  routeType: 'RouteType',
  region: 'Region',
  locationfind: 'Location Find',
  agree: 'Agree',
  pass: 'Pass',
  reject: 'Reject',
  reason: 'Reason',
  normal: 'Normal',
  limitedtime: 'Limited Time',
  avoid: 'Avoid',
  forbid: 'Forbid',
  //#运单列表',
  sale: 'Sale',
  purchase: 'Purchase',
  allocation: 'Allocation',
  baseinfo: 'BaseInfo',
  conductor: 'Vehicle Length (M / M) Length * Width * Height',
  carrierlicenseplatenumber: 'Carrier License Plate Number',
  orderNoofissuingparty: 'Order No. Of Issuing Party',
  orderamountofissuer: 'Order Amount Of Issuer',
  publisherenterprisename: 'Publisher Enterprise Name',
  issuersenterprisetaxpayeridentificationnumber:
    'Issuers Enterprise Taxpayer Identification Number',
  issuingmethod: 'Issuing Method',
  issuingtime: 'Issuing Time',
  freightamountofgoods: 'Freight Amount Of Goods',
  nameofserviceproviderowner: 'Name Of Service Provider(Owner)',
  orderreceivingmethod: 'Order Receiving Method',
  nameofgoods: 'Goods Name',
  cargovolumeM3: 'Cargo Volume (M3)',
  cargoweightTT: 'Cargo Weight (T / T)',
  cargounit: 'Cargo Unit',
  starttransportationtime: 'Start Transportation Time',
  endtransportationtime: 'End Transportation Time',
  distance: 'Distance',
  orderreceivetime: 'Order Receive Time',
  orderendtime: 'Order End Time',
  administrativedivisioncodeoftheplaceofdeparture:
    'Administrative Division Code Of The Place Of Departure',
  administrativedivisioncodeofarrivalplace:
    'Administrative Division Code Of Arrival Place',
  loadingandunloadingmode: 'Loading And Unloading Mode',
  detailedaddressoforigin: 'Detailed Address Of Origin',
  detailedaddressofdestination: 'Detailed Address Of Destination',
  warehousereceipt: 'Warehouse Receipt',
  billoflading: 'Bill Of Lading',
  photosofonsiteunloading: 'Photos Of On Site Unloading',
  onsiteloadingphotos: 'On Site Loading Photos',
  nameofpayee: 'Name Of Payee',
  bankcardnumber: 'Bank Card Number',
  bankjournal: 'Bank Journal',
  paymentmethod: 'Payment Method',
  paymentvoucher: 'Payment Voucher',
  locus: 'Locus',
  //#多品类管理',
  categorycode: 'Category Code',
  grant: 'Grant',
  newcategory: 'New Category',
  categoryremarks: 'Category Remarks',
  categoryremarksPla: 'Please enter category remarks',
  fieldproperties: 'Field Properties',
  newfield: 'New Field',
  fieldname: 'Field Name',
  fieldnamePla: 'only letters, numbers and underscores can be entered',
  chineseName: 'Chinese Name',
  chineseNamePla: 'Please enter Chinese name',
  fieldtype: 'Field Type',
  format: 'Format',
  formatPla:
    'Enter the code. Please maintain the drop-down content in the data dictionary',
  sort: 'Sort',
  sortPla: 'Please enter the sort order',
  defaultValue: 'Default value',
  defaultValuePla: 'Please enter a default value',
  required: 'Required',
  display: 'Display',
  width: 'Width',
  widthPla: 'Please enter the width',
  fieldLength: 'Field Length',
  fieldLengthPla: 'Please enter the field length',
  authorized: 'Authorized',
  grantCompanyNamePla: 'Please enter the enterprise name',
  authorize: 'Authorize',
  cancelauthorization: 'Cancel Authorization',
  Unauthorized: 'Unauthorized',
  ungrantCompanyNamePla: 'Please enter the enterprise name',
  textbox: 'Text Box',
  dropdownbox: 'Drop Down Box',
  datebox: 'Date Box',
  timeframe: 'Time Frame',
  valuebox: 'Value Box',
  //#签收预警',
  alertname: 'Alert Name',
  alertnamePla: 'Please enter the alert name',
  open: 'Open',
  routenotphotographed: 'Route Not Photographed',
  incompletetrack: 'Incomplete Track',
  retentiontime: 'Retention Time (Minutes)',
  //#货主预警',
  offlinetime: 'Offline Time',
  offlinewarn: 'Offline Warn',
  lengthofstay: 'Length Of Stay',
  staywarn: 'Stay Warn',
  sendMessage: 'Send Message',
  notPhotograph: 'Not Photograph Warn',
  //#竞价确认策略',
  policyname: 'Policy Name',
  policynamePla: 'Please enter a policy name',
  confirmednumber: 'Confirmed Number',
  points: 'Points',
  taxdeduction: 'Tax Deduction',
  biddingperiod: 'Bidding Period(Minutes)',
  //#自动签收',
  hour: 'Hour',
  minutes: 'Minutes',
  executeonce: 'Execute Once',
  repeatexecution: 'Repeat Execution',
  starttime: 'Start Time',
  driversignsPla: 'A few minutes after the driver signs for it',
  planType: 'Plan Type',
  executionfrequency: 'Execution Frequency',
  duration: 'Duration(Minutes)',
  describe: 'Describe',
  //# 车辆变更',
  carnumber: 'Car Number',
  carnumberPla: 'Enter the license plate number',
  carowner: 'Car Owner',
  carownerPla: 'Enter the owners name',
  carrycapacity: 'Carry Capacity',
  carrycapacityPla: 'Input Load Capacity',
  auditstatus: 'Audit Status',
  audit: 'Audit',
  yellowcard: 'Yellow Card',
  bluecard: 'Blue Card',
  approved: 'Approved',
  pendingapproval: 'Pending Approval',
  auditfailed: 'Audit Failed',
  nameofnewcarowner: 'Name Of New Car Owner',
  newcarmaintelephone: 'New Car Main Telephone',
  nameoforiginalowner: 'Name Of Original Owner',
  telephonenumberoforiginalowner: 'Phone Number Of Original Owner',
  pictureinfo: 'Picture Info',
  roadtransportpermit: 'Road Transport Permit',
  vehiclelicense: 'Vehicle License',
  frontofvehicle: 'Front Of Vehicle',
  vehicleside: 'Vehicle Side',
  reviewcomments: 'Review Comments',
  reviewcommentsPla: 'Please enter review comments',
  reviewedreturned: 'Reviewed Returned',
  cancelapproval: 'Cancel Approval',
  nameofvehicleowner: 'Name Of Vehicle Owner',
  location: 'Location',
  //#车辆列表',
  businessname: 'Business Name',
  businessnamePla: 'Enter the business name',
  nameofreviewer: 'Name Reviewer',
  endtime: 'End Time',
  modify: 'Modify',
  task: 'Task',
  lessee: 'Lessee',
  carlength: 'Car Length',
  carWigth: 'Car Width',
  carHeigth: 'Car Heigth',
  certificateinfo: 'Certificate Info',
  issuingauthority: 'Issuing Authority',
  licenseRegisterTime: 'License Register Time',
  dateissue: 'Date Issue',
  everyone: 'Everyone',
  useNaturel: 'Use Naturel',
  vehiclebrand: 'Vehicle Brand',
  vehicleNumber: 'Vehicle Number',
  roadTransportCertificateNo: 'Road Transport Certificate No.',
  beexecuted: 'Be Executed',
  intransit: 'In Transit',
  completed: 'Completed',
  added: 'Added',
  beconfirmed: 'Be Confirmed',
  Notuploaded: 'Not Uploaded',
  Uploaded: 'Uploaded',
  gasoline: 'Gasoline',
  dieseloil: 'Diesel Oil',
  electric: 'Electric',
  naturalgas: 'Natural Gas',
  Hybrid: 'Hybrid',
  other: 'Other',
  Licenseplatecolor: 'License Plate Color',
  carTypeName: 'Car Type Name',
  maximumpayload: 'Maximum Payload',
  Totalmass: 'Total Mass(kg)',
  //#客户反馈',
  feedbackperson: 'Feedback Person',
  query: 'Query',
  noreply: 'No Reply',
  replied: 'Replied',
  reply: 'Reply',
  see: 'Look',
  feedbackinfo: 'Feedback Info',
  customerreply: 'Customer Reply',
  //#物料管理',
  materialname: 'Material Name',
  typename: 'Type Name',
  typenamePla: 'Please enter a type name',
  materialtype: 'Material Type',
  materialtypePla: 'Please select material type',
  GSmaterialtype: 'GS Material Type',
  GSmaterialtypePla: 'Please select GS material type',
  materialNo: 'Material No.',
  materialnamePla: 'Please enter the material name',
  //#车辆品牌管理',
  brandname: 'Brand Name',
  brandnamePla: 'Please enter the brand name',
  branddescription: 'Brand Description',
  branddescriptionPla: 'Please enter the brand description',
  //#物类管理',
  categoryname: 'Category Name',
  categorypicture: 'Category Picture',
  uploadtips: 'Click Upload Or Drag The File Here',
  successrender: 'Render After Uploading Successfully',
  superior: 'Superior',
  superiorPla: 'Please select your superior',
  //#电子锁',
  passwordlockID: 'Password Lock ID',
  generalpassword: 'General Password',
  alternatepassword: 'Alternate Password',
  indexgetpassword: ' Get Password Index',
  addgroup: 'Add Group',
  addlock: 'Add Lock',
  importlock: 'Import Lock',
  groupname: 'Group Name',
  groupnamePla: 'Please enter a group name',
  passwordlockIDPla: 'Please enter password lock ID',
  file: 'File',
  uploadFile: 'Upload File',
  grouppasswordindex: 'Group Password Index',
  generalpasswordindex: 'General Password Index',
  alternatepasswordindex: 'Spare Password Index',
  passwordset: 'Password Set',
  alternatepasswordindexPla: 'Please enter an index number of 1-10000',
  around: 'Around',
  oneTotenPla: 'Please fill in the number from 0 to 10',
  posi: 'Position',
  obtain: 'Obtain',
  //#船舶列表',
  shipowner: 'Ship Owner',
  shipname: 'Ship Name',
  shipVolume: 'Ship Volume',
  shipNumberId: 'ShipNumberId',
  watertransportpermit: 'Water Transport Permit',
  shipLength: 'ShipLength',
  shipWidth: 'ShipWidth',
  shipHeight: 'ShipHeight',
  drivingLicense: 'Driving License',
  shipsideView: 'Ship SideView',
  //#船舶变更',
  applicantname: 'Applicant Name',
  applicantphone: 'Applicant Phone',
  shipid: 'Ship ID',
  newshipowner: 'New Shipowner',
  newshipownerphone: 'New Shipowner Phone',
  oldshipowner: 'Old Shipowner',
  oldshipownerphone: 'Old Shipowner Phone',
  vesselID: 'Vessel ID',
  //#超时未运输',
  advanceshipmentNo: 'Advance Shipment No.',
  fromUserAddress: 'From UserAddress',
  toUserAddress: 'To UserAddress',
  drivername: 'Driver Name',
  carriername: 'Carrier Name',
  stopwaybill: 'Stop Waybill',
  stoptransport: 'Stop Transport',
  currentlocation: 'Current Location',
  logisticsinfo: 'Logistics Info',
  exceptioninfo: 'Exception Info',
  transferinfo: 'Transfer Info',
  dispatchdetails: 'Dispatch Details',
  maptrack: 'Map Track',
  transportevaluation: 'Transport Evaluation',
  confirmreceipt: 'Confirm Receipt',
  dispatchedcar: 'Dispatched Car',
  closed: 'Closed',
  notsigned: 'Not Signed',
  endtransport: 'End Transport',
  //#线性一览表',
  bigwaybillNo: 'Big Waybill No.',
  littlewaybillNo: 'Little Waybill No.',
  dispatchCarNo: 'Dispatch Car No.',
  shipper: 'Shipper',
  receiver: 'Receiver',
  wayBillType: 'WayBill Type',
  selectwayBillType: 'Select WayBillType',
  signstatus: 'Sign Status',
  carriercompany: 'Carrier Company',
  carrier: 'Carrier',
  transporunitprice: 'Transport Unitprice',
  freightvolume: 'Freight Volume',
  process: 'Process',
  abnormalposition: 'Abnormal Position',
  sign: 'Sign',
  transportfile: 'Transport File',
  taskdetail: 'Task Detail',
  transportconfirm: 'Transport Confirm',
  signin: 'Sign In',
  loadphoto: 'Load Photo',
  exfactorypounds: 'Ex Factory Pounds',
  starttransport: 'Start Transport',
  arrivalnotice: 'Arrival Notice',
  deliveryphoto: 'Delivery Photo',
  arrivalconfirm: 'Arrival Confirm',
  ownersigned: 'Owner Signed',
  linearTips:
    'Ant line percentage indicates the proportion of actual mileage and preset mileage',
  weighing: 'Weighing',
  driverSignInWeight: 'Driver SignInWeight',
  confirmsignedquantity: 'Confirm Signed Quantity',
  weighingphotos: 'Weighing Photos',
  carrierbusiness: 'Carrier Business',
  shipmentplace: 'Shipment Place',
  receiptplace: 'Receipt Place',
  nodata: 'Not Data',
  logisticstracking: 'Logistics Tracking',
  presetline: 'Preset Line',
  beidouline: 'Beidou Line',
  eagleeyeline: 'Eagle Eyeline',
  trackplayback: 'Track Playback',
  customerName: 'Customer Name',
  nameofshippingunit: 'Name Of Shipping Unit',
  nameofreceivingunit: 'Name Of Receiving Unit',
  starttransporttime: 'Start Transport Time',
  endtransporttime: 'End Transport Time',
  driverphone: 'Driver Phone',
  dispatchtime: 'Dispatch Time',
  totalwaybill: 'Total Waybill',
  accruedamount: 'Accrued Amount',
  driverSignIntime: 'Driver SignIntime',
  suttle: 'Suttle',
  ownersignedweight: 'Owner Signedweight',
  inFactoryTime: 'InFactory Time',
  oneWeightTime: 'One WeightTime',
  twoWeightTime: 'Two WeightTime',
  outFactoryTime: 'Out FactoryTime',
  gross: 'Gross',
  tare: 'Tare',
  signInWeight: 'Sign In Weight',
  remainingquantity: 'Remaining Quantity',
  signinfo: 'Sign Info',
  thirdPartyNo: 'Third Party No.',
  thirdDispatchCarNo: 'Third Dispatch Car No.',
  closedsigned: 'Closed Signed By',
  adjuster: 'Adjuster',
  closedsignedreason: 'Closed (Signed) Reason',
  adjustmentreason: 'Adjustment Reason',
  platformreconcilor: 'Platform Reconcilor',
  ownersreconcilor: 'Owners Reconcilor',
  ownerreconciliationtime: 'Owner Reconciliation Time',
  carrierreconcilor: 'Carrier reconcilor',
  carrierreconciliationtime: 'Carrier Reconciliation Time',
  dispatchcarperson: 'Dispatch Carperson',
  Saleswaybill: 'Sales Waybill',
  Purchasewaybill: 'Purchase Waybill',
  Transferwaybill: 'Transfer Waybill',
  distances: 'Distance',
  //#货主列表',
  realname: 'Real Name',
  phonenumber: 'Phone Number',
  IDnumber: 'ID Number',
  reviewername: 'Reviewer Name',
  authorizationstatus: 'Authorization Status',
  nopicture: 'No Picture',
  updatePower: 'Update Power',
  selectPower: 'Select Power',
  selectAllPower: 'Select AllPower',
  secretKey: 'SecretKey',
  openadmin: 'Open Admin',
  closeadmin: 'Cacle Admin',
  refreshpassword: 'Refresh Password',
  denyauthorization: 'Deny Authorization',
  departmentName: 'Department Name',
  lastVisitTime: 'Last Visit Time',
  province: 'Province',
  city: 'City',
  district: 'District',
  frontIDcard: 'Front IDCard',
  backIDcard: 'Back IDCard',
  selectrole: 'Select Role',
  viewpower: 'View Power',
  authorizationkey: 'Authorization Key',
  generatekey: 'Generate Key',
  //#承运商列表',
  disablenetworkfreight: 'Disable Network Freight',
  enablenetworkfreight: 'Enable Network Freight',
  mydriver: 'My Driver',
  merchantaccount: 'Merchant Account',
  carrierType: 'Carrier Type',
  businessNum: 'Business No.',
  roadtransportpermitNo: 'Road Transport Permit No.',
  businessvalidTime: 'Business Valid Time',
  businessvalidStatus: 'Business Valid Status',
  recordCard: 'Record Card',
  businessLicense: 'Business License',
  powerAttorney: 'Power Attorney',
  legalPerson: 'Legal Person',
  legalPersonIDCard: 'Legal Person IDCard',
  legalPersonIDCardfront: 'Legal Person IDCard Front',
  createTime: 'Create Time',
  modifytime: 'Modify Time',
  sealhorizontaltextcontent: 'Seal Horizontal Text Content',
  sealhorizontaltextcontentPla:
    'The horizontal text content in the generated seal can be set to 0-8 characters. After the enterprise name exceeds 25 characters, it is not supported to set the XX special seal, such as the special seal for contract and the special seal for finance',
  seallastquartercontent: 'Seal Last Quarter Content',
  seallastquartercontentPla:
    '0-20 characters can be set for the lower chord text content in the generated seal. After the enterprise name exceeds 25 characters, the lower chord text cannot be set',
  sealtype: 'Seal Type',
  sealcolor: 'Seal Color',
  sealphoto: 'Seal Photo',
  sealphotoAlt: 'no Signature Picture',
  individualcarrier: 'Individual Carrier',
  generaltaxpayer: 'General Taxpayer',
  smallbusiness: 'Small Business',
  standardseal: 'Standard Seal',
  ovalseal: 'Oval Seal',
  roundpentagram: 'Round Without Pentagram',
  //#司机列表',
  selectCarrier: 'Select Carrier',
  driver: 'Driver',
  crew: 'Crew',
  drivertype: 'Driver Type',
  driverlicensepic: 'Driver License Photo',
  vehicleowner: 'Vehicle Owner',
  carriageleader: 'Carriage Leader',
  carriercarnum: 'Carrier Car Number',
  attachedvehicle: 'Attached Vehicle',
  actualowner: 'Actual Owner',
  ownercontact: 'Owner Contact',
  carriermodel: 'Carrier Model',
  vehiclelicensepic: 'Vehicle License Photo',
  vehiclephotos: 'Vehicle Photos',
  carryingcapacity: 'Carrying Capacity',
  affiliationstatement: 'Affiliation Statement',
  driverIDcard: 'Driver IDCard',
  publisheruniqueID: 'Publisher Unique ID',
  driverlicensephoto: 'Driver License Photo',
  driverlicensenumber: 'Driver License Number',
  accountholdername: 'Account Holder Name',
  accountholderIDnumber: 'Account Holder IDNumber',
  bankdeposit: 'bank deposit',
  serviceregistertime: 'Service Register Time',
  affiliationagreement: 'Affiliation Agreement',
  roletype: 'Role Type',
  regionalismcode: 'Regionalism Code',
  traveldocumentnumber: 'Travel Document Number',
  permitteddrivingmodel: 'Permitted Driving Model',
  licenseissuingauthority: 'License Issuing Authority',
  driverValidEndTime: 'Driver Valid EndTime',
  driverValidStartTime: 'Driver Valid StartTime',
  qualificationNum: 'Qualification Num',
  qualificationphoto: 'Qualification Photo',
  //#变更手机号',
  selectroletype: 'Select Role Type',
  carrierP: 'Carrier',
  newphone: 'New Phone',
  oldPhone: 'Old Phone',
  //#公司列表',
  companyNamePla: 'Please enter the company name',
  synchro: 'Synchro',
  auditcertification: 'Audit Certification',
  refuseauthentication: 'Refuse Authentication',
  certified: 'Certified',
  undercertification: 'Under Certification',
  authenticationfailed: 'Authentication Failed',
  becertified: 'Be Certified',
  viewQRcode: 'View QR Code',
  companyshortName: 'Company Short Name',
  companyshortNamePla: 'Please enter the company abbreviation',
  companyType: 'Company Type',
  selectcompanytype: 'Select Company Type',
  AlQaeda: 'AlQaeda',
  selectAlQaeda: 'Select AlQaeda',
  businesslicenseNo: 'Business License No.',
  businesslicenseNoPla: 'Please enter the business license number',
  legalPersonname: 'Legal Person Name',
  legalPersonnamePla: 'Please enter the name of the legal person',
  legalPersoncertificateNo: 'Legal Person Certificate No.',
  legalPersoncertificateNoPla: 'Please enter the legal person Id number',
  handledby: 'Handler',
  handledbyPla: 'Please enter the handler',
  handlerIDNo: 'Handler ID No.',
  handlerIDNoPla: 'Please enter the ID number of the handler',
  handlerphone: 'Handler Phone',
  handlerphonePla: 'Please enter the phone number of the operator',
  affiliatedcompany: 'Affiliated Company',
  certificatevalidity: 'Certificate Validity',
  certificatevalidityPla: 'Please select the valid period of business license',
  forwardingfirm: 'Forwarding Firm',
  pilotenterprise: 'Pilot Enterprise',
  whetherbelongsCNBM: 'Whether Belongs CNBM',
  templateLimit: 'Template Limit',
  templateLimitPla: 'Please enter the template limit',
  subTemplateLimit: 'Sub Template Limit',
  subTemplateLimitPla: 'Please enter the sub account template limit',
  companydescription: 'Company Description',
  companydescriptionPla: 'Please enter company description',
  companylogo: 'Company LOGO',
  businesslicensepicture: 'Business License Picture',
  citydistrict: 'City/District',
  county: 'County',
  detailaddress: 'Detail Address',
  detailaddressPla: 'Please enter the detailed address',
  enterpriseuseraccount: 'Enterprise User Account',
  legalrepresentative: 'Legal Representative',
  companyaddress: 'Company Address',
  businessscope: 'Businessscope',
  enterprisetype: 'Enterprise Type',
  enterprisenametaxpayername: 'Enterprise Name(Taxpayer Name)',
  registerservicepublishtime: 'Register Service Publish Time',
  enterprisetaxpayeridentificationnumber:
    'Enterprise Taxpayer Identification Number',
  enterprisephonenumber: 'Enterprise phone number',
  dateIncorporation: 'Date Incorporation',
  regionalismcodecity: 'Regionalism Code(City)',
  registeredcapital: 'Register Edcapital',
  competenttaxauthority: 'Competent Tax Authority',
  rejectReason: 'Reject Reason',
  rejectReasonPla: 'Please enter the reason for rejection',
  group: 'Group',
  company: 'Company',
  subsidiary: 'Subsidiary',
  branchoffice: 'Branch Office',
  Sealmanagement: 'Seal Management',
  notSealTips: 'No Seal',
  reasonPla: 'Please specify the reason for cancellation of authorization',
  isUseEsign: 'Enable Electronic Signature',
  //#公司同步配置',
  enterprisename: 'Enterprise Name',
  keywordPla: 'Enter Keyword',
  ordernotreturned: 'Order Not Returned',
  orderreturn: 'Order Return',
  balanceordersynchronization: 'Balance Order Synchronization',
  addconfiguration: 'Add Configuration',
  openclose: 'Open|Close',
  yesno: 'Yes|No',
  querybytimeperiod: 'Query By Time Period',
  querybymonth: 'Query By Month',
  returnstatus: 'Return Status',
  dataPage: 'Data Page',
  closeDispatch: 'Close Dispatch',
  customerSync: 'Customer Sync',
  dispatchSync: 'Dispatch Sync',
  enclosureSync: 'Enclosure Sync',
  thirdCloseDispatch: 'Third Close Dispatch',
  saleWeightSync: 'Sale Weight Sync',
  purchaseWeightSync: 'Purchase Weight Sync',
  isShort: 'Short Switch Service',
  lineUpEnclosure: 'Line UpEnclosure',
  relatedBusiness: 'Related Business',
  timeQuery: 'Time Query',
  moreLoadUnload: 'Whether to open multiple loading and unloading',
  moreTransport: 'Whether to open multimodal transport',
  useEsign: 'Whether to open the electronic signature',
  //#统计分析',
  transportmanagement: 'Transport Management',
  vehiclestransit: 'In Car',
  transportweight: 'Weight',
  onlinevehicle: 'On Car',
  offlinevehicle: 'Not Car',
  settleinfo: 'Settle Info',
  settledshipper: 'Owner',
  residentdriver: 'Driver',
  inboundcarrier: 'Carrier',
  parkedvehicle: 'Car',
  todolist: 'To Do List',
  vehiclebereviewed: 'Car Audit',
  driverbereviewed: 'Driver Audit',
  carrierbereviewed: 'Carrier Audit',
  driverinfo: 'Driver Info',
  activedriver: 'Active Driver',
  commondriver: 'Common Driver',
  commonvehicles: 'Common Car',
  waybillstatistics: 'Waybill Statistics',
  dispatchstatistics: 'Dispatch Statistics',
  vehiclesstatistics: 'Vehicles Statistics',
  driverstatistics: 'Driver Statistics',
  paystatistics: 'Pay Statistics',
  newwaybillquantitytoday: 'New Waybill Quantity Today',
  deliverycompletedtoday: 'Delivery Completed Today',
  newdistributiontoday: 'New Distribution Today',
  numberofnewvehiclestoday: 'Number Of New Vehicles Today',
  numberofnewdriverstoday: 'Number Of New Drivers Today',
  freightsettlementamounttoday: 'Freight Settlement Amount Today',
  //#合同管理',
  signing: 'Signing',
  signedP: 'Signed',
  signingfailed: 'Signing Failed',
  newcontract: 'New Contract',
  uploadcontract: 'Upload Contract',
  contractvalidtime: 'Contract Valid Time',
  selectcontracttype: 'Select Contract Type',
  legalapproval: 'Legal Approval',
  customerBusinessType: 'Customer Business Type',
  cooperation: 'Cooperation',
  registeredCapitalOfClients: 'Registered Capital Clients',
  grossProfitCalculation: 'Gross Profit Calculation',
  contractsNum: 'Contracts Num',
  customerContact: 'Customer Contact',
  summaryOfContractSigning: 'Summary Contract Signing',
  contactMobile: 'Contact Mobile',
  settlementinfo: 'Settlement Info',
  packPrice: 'Pack Price',
  freightrate: 'Freight Rate',
  goodsNum: 'Goods Num',
  contractcontent: 'Contract Content',
  enclosure: 'Enclosure',
  upload: 'Upload',
  enclosuretip: '(Please upload PDF file)',
  //#合同模板',
  templatename: 'Template Name',
  templatenamePla: 'Enter Template Name',
  templateType: 'Template Type',
  templateApplication: 'Application Type',
  templateTypePla: 'Please select a template type',
  newbuild: 'New Build',
  publish: 'Publish',
  failure: 'Invalid',
  templateinfo: 'Template Info',
  templateCode: 'Template Code',
  templateCodePla: 'Automatically generated by the system',
  businessType: 'Business Type',
  selectbusinessType: 'Select Business Type',
  organization: 'Organization',
  carrierenterprise: 'Carrier Enterprise',
  contractvalidity: 'Contract Validity',
  templatecontent: 'Template Content',
  platformownercontract: 'Platform Owner Contract',
  platformcarriercontract: 'Platform Carrier Contract',
  platformdrivercontract: 'Platform Driver Contract',
  ownercarrier: 'Owner Carrier',
  carrierplatformtransfernetworkfreight:
    'Carrier Platform（Transfer Network Freight）',
  affiliationagreementP: 'Affiliation Agreement',
  wnerstatement: 'Wner Statement',
  transportationcontract: 'Transportation Contract',
  servicecontract: 'Service Contract',
  agreememt: 'Agreememt',
  networkFreight: 'Network Freight',
  nonnetworkfreight: 'Non Network Freight',
  Validincurrentyear: 'Valid In Current Year',
  halfayear: 'Half A Year',
  oneyear: 'One Year',
  twoyear: 'Two Year',
  threeyear: 'Three Year',
  firstquarter: 'First Quarter',
  //#运输合同',
  contractCode: 'Contract Code',
  contractCodePla: 'Please enter the contract number',
  contractName: 'Contract Name',
  contractNamePla: 'Please enter the contract name',
  firstParty: 'FirstParty',
  firstPartyPla: 'Please enter the name of Party A',
  partyB: 'PartyB',
  partyBPla: 'Please enter the name of Party B',
  signingMethod: 'Signing Method',
  contractType: 'Contract Type',
  transportType: 'Transport Type',
  previewcontract: 'Preview Contract',
  generalcontracting: 'General Contracting',
  selfsupport: 'Self Support',
  platformbusines: 'Platform Busines',
  effect: 'Take Effect',
  online: 'Online',
  offline: 'Offline',
  platformdriver: 'Platform Driver',
  platformcarrier: 'Platform Carrier',
  platformowner: 'Platform Owner',
  landcarriage: 'Land Carriage',
  watercarriage: 'Water Carriage',
  //#服务合同',
  signingtime: 'Signing Time',
  signingtimePla: 'Please enter the signing time',
  Initiatesigning: 'Initiate Signing',
  PartyAsigns: 'Party A Signs',
  PartyACancellation: 'Party A Cancellation',
  PartyBsigns: 'Party B Signs',
  PartyBCancellation: 'Party B Cancellation',
  duedate: 'Due Date',
  duedatePla: 'Please enter the due date',
  //#日志挂管理',
  operatorTime: 'Operator Time',
  operatorTimePla: 'Please select the operation time',
  //#知识库管理',
  theme: 'Theme',
  //#承运商派车情况',
  startdate: 'Start Date',
  enddate: 'End Date',
  //#派车单统计',
  summaryinfo: 'Summary Info',
  dispatchcarnumber: 'Dispatch Car Number',
  orders: 'Orders',
  amountofvehicledispatchslip: 'Amount Of Vehicle Dispatch Slip',
  totalsignedestimated: 'Total Signed(Estimated)',
  totalfreightestimated: 'Total Freight(Estimated)',
  totalplannedmileage: 'Total Planned Mileage',
  actualtotalmileage: 'Actual Total Mileage',
  milliontons: 'Million Tons',
  millionkilometers: 'Million Kilometers',
  notopen: 'Not Open',
  signed: 'Signed',
  abnormalsign: 'Abnormal Sign',
  //#运单统计',
  wayBillNo: 'Waybill No.',
  orderquantity: 'Order Quantity',
  ton: 'Ton',
  transportedvolume: 'Transported Volume',
  intransitvolume: 'In Transit Volume',
  nottransported: 'Not Transported',
  //#审核统计',
  vehicle: 'Vehicle',
  changevehicle: 'Change Vehicle',
  aship: 'Ship',
  //#框架协议管理',
  agreementName: 'Agreement Name',
  agreementNamePla: 'Please enter the agreement name',
  agreementType: 'Agreement Type',
  agreementinfo: 'Agreement Info',
  selectsignway: 'Select Sign Way',
  role: 'Role',
  agreementcontent: 'Agreement Content',
  owner: 'Owner',
  consignee: 'Consignee',
  useragreement: 'User Agreement',
  privacypolicy: 'Privacy Policy',
  electronicsigning: 'Electronic Signing',
  //#结算规则配置',
  batchpaymentconfig: 'Batch Payment Config',
  settlementQuantityRange: 'Settlement Quantity Range',
  inputPla: 'Please enter',
  maximumamountperorderreminder: 'Maximum Amount Per Order Reminder',
  settlementamountadjustmentconfig: 'Settlement Amount Adjustment Config',
  yuan: 'Yuan',
  batchsave: 'Batch Save',
  //#管理员系统设置',
  //#账号管理',
  account: 'Account',
  allstate: 'All State',
  updatebusinessroles: 'Update Business Roles',
  updaterole: 'Update Role',
  viewrole: 'View Role',
  accountPla: 'Please enter the account number',
  namePla: 'Please enter your name',
  phonenumberPla: 'Please enter your mobile phone number',
  emailPla: 'Please enter email',
  affiliateddepartment: 'Affiliated Department',
  remarks: 'Remarks',
  remarksPla: 'Please enter comments',
  owneraccountsync: 'Owner Account Sync',
  ownerrole: 'Owner Role',
  //#部门管理',
  departmentNamePla: 'Please enter the Department name',
  newadd: 'New Add',
  //#管理员权限管理',
  catalogue: 'Catalogue',
  menu: 'Menu',
  button: 'Button',
  permissionName: 'Permission Name',
  permissionNamePla: 'Please enter the permission name',
  systemsource: 'System Source',
  sortNo: 'Sort No.',
  sortNoPla: 'Please enter the sorting number',
  permissiontype: 'Permission Type',
  menuurl: 'Menu Url',
  menuurlPla: 'Please enter the menu URL',
  permissioncode: 'Permission Code',
  permissioncodePla: 'Please enter permission code',
  menuicon: 'Menu Icon',
  menuiconPla: 'Please enter the menu icon',
  uppermenu: 'Upper Menu',
  //#管理员角色管理',
  authorityallocation: 'Authority Allocation',
  dataauthorization: 'Data Authorization',
  valid: 'Valid',
  invalid: 'Invalid',
  roleName: 'Role Name',
  roleNamePla: 'Please enter a role name',
  permissionlist: 'Permission List',
  selected: 'Selected',
  optional: 'Optional',
  buttonName: 'Button Name',
  //#数据权限定义',
  adddatapermissions: 'Add Data Permissions',
  drop: 'Drop',
  table: 'Table',
  basicTable: 'Basic Table',
  basicTablePla: 'Please enter the basic table',
  basicTableName: 'Basic Table Name',
  basicTableNamePla: 'Please enter the name of the basic table',
  permissionField: 'Permission Field',
  permissionFieldPla: 'Please enter the permission field',
  permissionFieldName: 'Permission Field Name',
  permissionFieldNamePla: 'Please enter the permission field name',
  permissionFieldValue: 'Permission Field Value',
  permissionFieldValuePla: 'Please enter the permission value',
  permissionFieldMode: 'Permission Field Mode',
  allowaccessbydefault: 'Allow Access By Default',
  accessdisabledbydefault: 'Access Disabled By Default',
  //#数据字典管理',
  dictionarydetails: 'Dictionary Details',
  code: 'Code',
  codePla: 'Please enter the code',
  namePlas: 'Please enter a name',
  describePla: 'Please enter a description',
  //#意见反馈',
  userName: 'Username',
  contactnumber: 'Contact Number',
  viewdetails: 'View Details',
  read: 'Read',
  unread: 'Unread',
  messagecontent: 'Message Content',
  additionalpictures: 'Additional Pictures',
  replyContent: 'Reply Content',
  replyTime: 'Reply Time',
  replyName: 'Reply Name',
  //#企业管理',
  //#用户管理',
  refreshdeparment: 'Refresh Deparment',
  //#组织管理',
  applycertification: 'Apply Certification',
  viewseal: 'View Seal',
  maintenanceseal: 'Maintenance Seal',
  red: 'Red',
  blue: 'Blue',
  black: 'Black',
  circularsealfivepointedstar: 'Circular Seal Five Pointed Star',
  ovalseals: 'Oval Seal',
  roundnopentagram: 'Round No Pentagram',
  sealhorizontalTips:
    'Note: generate the horizontal text content in the seal； 0-8 characters can be set. When the enterprise name exceeds 25 characters, horizontal text setting is not supported； Generally refers to XX special seal, such as special seal for contract, special seal for finance, etc',
  seallastquarterTips:
    'Note: generate the lower chord text in the seal； 0-20 characters can be set. After the enterprise name exceeds 25 characters, the setting of the lower chord text is not supported； The second string refers to a string of anti-counterfeiting numbers at the bottom of your company‘s official seal. If there is no such parameter, you can leave it blank',
  Exampleofseal: 'Example Of Seal',
  //#登录页',
  adminlogin: 'Admin Login',
  adminaccountPla: 'Please enter administrator account',
  adminpasswordPla: 'Please enter the login password',
  login: 'Login',
  PlatformManagementSide: 'Platform Management Side',
  //#index.html',
  changepassword: 'Change Password',
  signout: 'Sign Out',
  indextitle: 'WZC Intelligent Logistics Platform',
  news: 'News',
  todolists: 'To Do List',
  notice: 'Notice',
  Entermymessage: 'My News',
  Cleartodo: 'Clear To Do',
  emptyNotice: 'Empty Notice',
  Falsealarm: 'False Alarm',
  messageTitle: 'Message Title',
  messageContent: 'Message Content',
  Batchread: 'Batch Read',
  Falsepositivedescription: 'False Positive Description',
  Selectmultiplefiles: 'Select Multiple Files',
  pictureVideoUpload: 'picture / VideoUpload',
  filename: 'FileName',
  fileSize: 'FileSize',
  Startupload: 'Start Upload',
  Reupload: 'Re Upload',
  //#修改密码',
  originalpassword: 'Original Password',
  originalpasswordPla: 'Please enter the original password',
  newpassword: 'New Password',
  newpasswordPla: 'Please enter a new password',
  confirmpassword: 'Confirm Password',
  confirmpasswordPla: 'Please enter the new password again',
  //#细节优化',
  closeCurrentTab: 'Close Current Tab',
  closeOtherTab: 'Close Other Tab',
  closeAllTab: 'Close All Tab',
  sideexpansion: 'Side Expansion',
  refresh: 'Refresh',
  fullScreen: 'Full Screen',
  //#页面lay-reqtext',
  frequencyreqtext: 'Please set the execution frequency',
  durationreqtext: 'Please set the duration',
  confirmednumberreqtext: 'Please set the number of people',
  pointsreqtext: 'Please set the deduction point',
  taxdeductionreqtext: 'Please set tax deduction',
  biddingperiodreqtext: 'Please set the bidding period',
  offlinetimereqtext: 'Please set the offline duration',
  lengthofstayreqtext: 'Please set the retention time',
  sendMessagereqtext: 'Please set the sending station message',
  //#报警管理',
  Alarmstarttime: 'Alarm Start Time',
  Alarmendtime: 'Alarm End Time',
  alarmOptionNo: 'Alarm Option No.',
  alarmOptionName: 'Alarm Option Name',
  Noticedetails: 'Notice Details',
  Processinglog: 'Processing Log',
  Realtimealarm: 'Real Time Alarm',
  Monitoringalarm: 'Monitoring Alarm',
  Normalalarm: 'Normal Alarm',
  bigwaybill: 'Big Waybill',
  littlewaybill: 'Little Waybill',
  dispatchCarwaybill: 'Dispatch Car waybill',
  viewPictureVideo: '查看图片/视频',
  mail: 'Mail',
  Appmessage: 'App Message',
  shortmessage: 'Short Message',
  emails: 'E-Mail',
  Webmanualprocessing: 'Web Manual Processing',
  Messagefeedbackprocessing: 'Message Feedback Processing',
  DownloadView: 'Download View',
  Video: 'Video',
  //#大屏',
  Alarmoverview: 'Alarm Overview',
  Fullplatform: 'Full Platform',
  Numberofalarms: 'Number of Alarms',
  Coveragetoday: 'Coverage Today',
  Cities: 'Cities',
  AllCity: 'National Cities / Districts / Counties',
  produce: 'Produce',
  Alarms: 'Alarms',
  Numberofalarmstoday: 'Number Of Alarms Today',
  importantAlarmCount: 'Key Alarm Types And Times Today',
  Alarmyearonyear: 'Alarm (year-on-year)',
  Todayyesterdaytrend: 'Today‘s Vs. Yesterday’s Trend',
  Regionalproportion: 'Regional Proportion',
  //#报警级别',
  alarmLevel: 'Alarm Level',
  noticeway: 'Notice Way',
  noticeLevel: 'Notice Level',
  //#报警项管理',
  nothing: 'Nothing',
  formula: 'Formula',
  Remotecall: 'Remote Call',
  exist: 'Exist',
  Alarmtype: 'Alarm Type',
  effectTime: 'Effect Time',
  effectTimePla: 'Please select a time',
  invalidTime: 'Invalid Time',
  monitorValue: 'Monitor Value',
  Unit: 'Unit',
  second: 'Second',
  Kilometers: 'Kilometers(KM)',
  Multiplematch: 'Multiple Match',
  Whetherthemessageneedstobeprocessed: 'Message Whether To Process',
  noticeCustomerService: 'Notice Customer Service',
  Alarmlevelupgrade: 'Alarm Level Upgrade',
  assignUseRoles: 'Assign Use Roles',
  Initialalarmlevel: 'Initial Alarm Level',
  selectAlarmLevel: 'Please select the alarm level',
  messageTitlePla: 'Parameter values can be obtained through ${variable name}',
  judgmentRule: 'Judgment Rule',
  ruleType: 'Rule Type',
  ruleContent: 'Rule Content',
  ruleContentPla:
    'When the rule type is formula, the value is formula； When the rule type is remote call, the value is URL link address',
  //#监控任务',
  monitorStartTime: 'Monitor StartTime',
  monitorEndTime: 'Monitor EndTime',
  monitorStatus: 'Monitor Status',
  Monitoring: 'Monitoring',
  stop: 'Stop',
  complete: 'Complete',
  Monitorparameters: 'Monitor Parameters',
  //#消息推送管理',
  noticeTimeFrom: 'Notice Time From',
  noticeTimeTo: 'Notice Time To',
  noticeStatus: 'Notice Status',
  Notnotified: 'Not Notified',
  Notificationsucceeded: 'Notification Succeeded',
  Notificationfailed: 'Notification Failed',
  Alarmmessagenumber: 'Alarm Message Number',
  Pushagain: 'Push Again',
  //#报警一览表',
  cleanUp: 'Clean Up',
  AlarmDetails: 'Alarm Detail',
  //#标签栏管理',
  //#指标库管理',
  Indexname: 'Indicator Name',
  IndexnamePla: 'Enter Indicator Name',
  indexType: 'Type',
  indexTypePla: 'Select The Type',
  isEnable: 'Is Enable',
  selectType: 'Select Type',
  //#标签库管理',
  tagName: 'Tag Name',
  tagtype: 'Tag Type',
  //#评价申诉处理',
  //#司机评价申诉',
  appealPerson: 'Appeal Person',
  appealStatus: 'Appeal Status',
  UnderAppeal: 'Under Appeal',
  Agreed: 'Agreed',
  Tonnageofcargo: 'Tonnage Of Cargo',
  ShippingAddress: 'Shipping Address',
  ShipToAddress: 'Ship To Address',
  Contentofownerevaluation: 'Content Of Owner Evaluation',
  Viewevaluationdetails: 'View Evaluation Details',
  Carrierevaluationcontent: 'Carrier Evaluation Content',
  Complainthandlingcontent: 'Complaint Handling Content',
  Handleopinions: 'Handle Opinions',
  HandleopinionsPla: '150 Words At Most',
  Pictureimport: 'Picture Import',
  Processresults: 'Process Results',
  getImageUrl: 'Get Image Url Value',
  //#承运商评价申诉',
  Carriercomplaintcontent: 'Carrier Complaint Content',
  Processingpictures: 'Processing Pictures',
  //#司机评分体系',
  Servicecapability: 'Service Capability',
  Promisekeepingrecord: 'Promise Keeping Record',
  Participation: 'Participation',
  qualifications: 'Qualifications',
  Thirdpartyevaluation: 'Third Party Evaluation',
  ServicecapabilityTips:
    'Service capability refers to the objective evaluation of the operation records of each node in the logistics transportation service process provided by the platform for each cargo owner',
  Servicerecord: 'Service Record',
  Servicescenario: 'Service Scenario',
  appointNum: 'Appoint Num',
  bidNum: 'Bid Num',
  businessTypeNum: 'Business Type Num',
  garbNum: 'Garb Num',
  materialTypeNum: 'Material Type Num',
  shipperNum: 'Shipper Num',
  times: 'Times',
  Several: 'Several',
  numbers: 'Number',
  PromisekeepingrecordTips:
    'The record of keeping an agreement refers to the objective evaluation of whether you have completed the order in real time as scheduled after competing for orders',
  ParticipationTips:
    'Participation refers to your ability to participate in platform transportation, order grabbing, bidding and other interactions with the platform after registering the platform',
  qualificationsTips:
    'Qualification refers to the objective evaluation of whether all certificates are complete and valid after you register on the platform',
  ThirdpartyevaluationTips:
    'The third-party evaluation refers to the subjective evaluation of your transportation task by the owner and the consignee. You can file a platform appeal against any doubtful evaluation',
  //#灰度管理',
  //#灰度服务管理',
  serverName: 'Service Name',
  serverNamePla: 'Please enter the service name',
  //#灰度用户管理',
  userType: 'UserType',
  Grayscaleservice: 'Grayscale Service',
  namePhoneNumber: 'Name/Phone Number',
  SelectService: 'Select Service',
  //#历史数据查询',
  //#历史数据转热审核列表',
  Batchaudit: 'BatchAudit',
  implement: 'Implement',
}
