import Cookies from 'js-cookie'

const TokenKey = 'XSRF-TOKEN'

export function getCookies() {
  return Cookies.get(TokenKey)
}

export function setCookies(token: string) {
  return Cookies.set(TokenKey, token)
}

export function removeCookies() {
  return Cookies.remove(TokenKey)
}
