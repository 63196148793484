import { createI18n } from 'vue-i18n'
import zhCn from '@/locale/lang/zh-cn'
import enUs from '@/locale/lang/en-us'

export const LOCALE_OPTIONS = [
  { label: '简体中文', value: 'zh-cn' },
  { label: 'English', value: 'en-us' }
]

const language = localStorage.getItem('wzc-locale') || 'zh-cn'
const i18n = createI18n({
  locale: language,
  fallbackLocale: 'zh-cn',
  globalInjection: true,
  allowComposition: true,
  messages: {
    'zh-cn': zhCn,
    'en-us': enUs
  }
})
export default i18n
