import axios from 'axios'
import {getCookies} from '@/utils/cookies'
import Cookies from "js-cookie";

const service = axios.create({
    baseURL: '', //import.meta.env.VITE_BASE_URL,
    timeout: 5000,
    headers: {
        WZCTK: localStorage.getItem('wzc-token'),
        'X-XSRF-TOKEN': getCookies()
    }
})
service.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
service.interceptors.response.use(
    (response) => {
        const {code} = response.data
        //35401 认证失败
        //35402  鉴权失败
        //35403  未选择身份
        if (code=== 35401 || code=== 35402 || code=== 35403){
            Cookies.set('islogin','false');
        }


        return response.data
    },
    (error) => {
        return Promise.reject(error)
    }
)
export default service
