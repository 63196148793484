import { createApp } from 'vue'
import router from './router'
import stores from '@/stores'
import i18n from '@/locale'
import ArcoVue, { Message } from '@arco-design/web-vue'
import btnPerms from "@/hooks/power";
import ArcoVueIcon from '@arco-design/web-vue/es/icon'

import App from './App.vue'

import '@/assets/style/index.less'

const app = createApp(App)
btnPerms(app)
Message._context = app._context
app.use(router)
app.use(stores)
app.use(i18n)
app.use(ArcoVue)
app.use(ArcoVueIcon)

app.mount('#app')
