<script setup lang="ts">
import useAppStore from '@/stores/modules/app'
import { computed } from 'vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn'
import useLocale from '@/hooks/locale'

const appStore = useAppStore()
const size = computed(() => {
    return appStore.size
})

const { current } = useLocale()
const locale = computed(() => {
    switch (current.value) {
        case 'zh-cn':
            return zhCN
        case 'en-us':
            return enUS
        default:
            return zhCN
    }
})
</script>

<template>
    <a-config-provider :locale="locale">
        <router-view />
    </a-config-provider>
</template>
