<script setup lang="ts">
import Navbar from "./components/Navbar/index.vue";
import Sidebar from "./components/Sidebar/index.vue";
import Breadcrumb from "./components/Breadcrumb/index.vue";
import useAppStore from "@/stores/modules/app";
import NavTab from "./components/NavTab.vue";
import { computed, onMounted, ref } from "vue";
import { getServicePhone, isWithinTimeRange } from '@/utils/commonUtils'
import { Message } from '@arco-design/web-vue'

const appStore = useAppStore();
const menuWidth = computed(() => appStore.menuWidth);

const phone = ref('')

const getPhone = async () => { 
  phone.value = ''
  const res = await getServicePhone()
  const phoneArr = res.filter((item: any) => (item.dutyType == 1 || item.dutyType == 0))
  if (phoneArr.length) {
    for (let timeArea of phoneArr) {
      if (isWithinTimeRange(timeArea.startTime, timeArea.endTime)) {
        phone.value = timeArea.mobile
      }
    }
  }
}

onMounted(() => { 
  getPhone()
})
</script>

<template>
  <a-config-provider :update-at-scroll="true">
    <navbar />
    <!-- <sidebar /> -->
    <main class="main-container">
      <!-- <NavTab /> -->
      <breadcrumb />
      <div class="context-wrapper">
        <router-view v-slot="{ Component, route }">
          <keep-alive :include="['settle-order-form']">
            <component :is="Component" :key="route.path" />
          </keep-alive>
        </router-view>
      </div>
    </main>
    <div class="footerBox">
      <div class="footerBox-left">
        <b>赛马物联科技（宁夏）有限公司</b>
        <div>
          赛马物联科技（宁夏）有限公司是宁夏建材集团二级子公司，是一家专业的信息
          技术咨询服务公司，以高新技术、创新服务为公司生产力和核心竞争力，专注于
          打造一支高技术、高效率、高素质的专业技术咨询服务团队；公司立足高端IT技
          术服务，提供基于互联网应用的技术咨询及开发、集成、推广服务，围绕企业安
          全生产管理及供应链管理领域.
        </div>
      </div>
      <div class="footerBox-center">
        <div>
          <span>联系地址：</span>
          <span>宁夏银川市、金凤区人民广场路219号建材大厦</span>
        </div>
        <div>
          <span>备案号：</span>
          <span>宁ICP备2020003213号</span>
        </div>
        <div>
          <span>公司电话：</span>
          <span style="font-size: 22px">{{ phone }}</span>
        </div>
      </div>
      <div class="footerBox-right">
        <div>
          <img src="@/assets/images/SJapp.png" alt="" />
          <span>司机APP</span>
        </div>
        <div>
          <img src="@/assets/images/HZapp.png" alt="" />
          <span>货主APP</span>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<style scoped lang="less">
.main-container {
  // padding-top: 50px;
  // padding-left: 180px;
}
.footerBox {
  width: 100%;
  height: 188px;
  background: #303540;
  padding: 24px 70px;
  display: flex;

  .footerBox-left {
    border-right: 1px solid #e8e9eb;
    padding-right: 56px;

    b {
      width: 196px;
      height: 22px;
      font-size: 14px;
      color: #f2f2f4;
      line-height: 22px;
      margin-bottom: 8px;
      font-weight: 500;
      display: block;
    }

    div {
      width: 490px;
      height: 110px;
      font-size: 14px;
      font-weight: 400;
      color: #f2f2f4;
      line-height: 22px;
    }
  }

  .footerBox-center {
    padding: 24px 56px;
    border-right: 1px solid #e8e9eb;

    div {
      color: #f2f2f4;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 12px;

      :nth-child(1) {
        width: 70px;
        height: 22px;
        font-weight: 400;
        display: inline-block;
      }
    }
  }

  .footerBox-right {
    display: flex;
    text-align: center;
    color: #f2f2f4;

    div {
      margin-top: 15px;
      margin-left: 58px;

      img {
        width: 80px;
        height: 80px;
        margin-bottom: 8px;
      }

      span {
        display: block;
      }
    }
  }
}
</style>
