export default function btnPerms(app) {
    app.directive('has', {
        mounted(el, binding) {
            if (!permsJudge(binding.value)) {
                el.parentNode.removeChild(el);
            }
            function permsJudge(value){
                sessionStorage.setItem("perms",'[]')
                let perms = JSON.parse(sessionStorage.getItem("perms") || '');
                // let perms=['system:user:list', 'system:user:query', 'system:user:add',
                // 'system:user:edit', 'system:user:delete', 'system:user:role',
                // 'system:user:resetPwd', 'system:role:list', 'system:role:query',
                // 'system:role:add', 'system:role:edit', 'system:role:delete',
                // 'system:role:menu', 'system:menu:list', 'system:menu:query',
                // 'system:menu:add', 'system:menu:edit', 'system:menu:delete']
                for (let item of perms) {
                    if (item === value) {
                        return true;
                    }
                }
                return true;
            }
        }
    });
}