import { defineStore } from 'pinia'

const useAppStore = defineStore('app', {
  state: () => ({
    menuCollapse: false,
    menuWidth: 210,
    screenHeight: 500,
    navTab: true,
    size: 'small'
  })
})
export default useAppStore
