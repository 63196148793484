import i18n from '@/locale'
import Message from '@arco-design/web-vue/es/message'
import router from '@/router'
import { dutyList } from '@/api/common'

export const { t } = i18n.global

export const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
export const idcard = /^\d{6}(?:19|20)?\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1-2]\d|3[01])\d{3}[\dX]$/
export const smsCodeReg = /^[0-9]{6}$/
export const password = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,20}$/
export const regex = /^[\da-zA-Z\u4e00-\u9fa5]+$/
export const regexnum = /^\d*$/
export const socialTrustCode = /^[a-zA-Z0-9]+$/

// 13 位时间戳转 YYYY-MM-DD HH:mm:SS
export const timestampToDateTime = (timestamp: number) => {
  const date = new Date(timestamp)
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  const h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':'
  const m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':'
  const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}

// 13 位时间戳转 YYYY-MM-DD
export const timestampToDate = (timestamp: number) => {
  const date = new Date(timestamp)
  const Y = date.getFullYear() + '-'
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  return Y + M + D
}

// 获取当前时间 YYYY-MM-DD HH:mm:SS
export const formatNewDate = () => {
  let date = new Date()
  let YYYY = date.getFullYear()

  let MM = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  let hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  let mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  //这里修改返回时间的格式
  return YYYY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss
}

// 秒换小时
export const secondsForHours = (seconds: number) => {
  return (seconds / 60 / 60).toFixed(2)
}

// 下载文件流
export const downloadFile = (content: any) => {
  // const link = document.createElement("a"); // 创建a标签
  let blob = new Blob([content], { type: 'application/vnd.ms-excel' }) //文件流处理
  // link.style.display = "none";  //去除a标签的样式
  // // 设置连接
  // link.href = URL.createObjectURL(blob);
  // // link.download = fileName;
  // document.body.appendChild(link);
  // //模拟点击事件
  // link.click();
  // //移除创建的a标签
  // window.URL.revokeObjectURL(link.href);
  // document.body.removeChild(link);
  const download_url = window.URL.createObjectURL(blob) //数据流作为下载的源
  const eleLink = document.createElement('a') //创建一个a标签
  eleLink.href = download_url //下载的文件连接，实际就是下载的数据流
  eleLink.download = '运单内容.xlsx' //下载的文件名称
  document.body.appendChild(eleLink)
  eleLink.click() //模拟下载文件
  window.URL.revokeObjectURL(download_url)
  Message.success(`导出成功!`)
}

// 获取第一个可用页面路由地址
export const getFirstPath = (routers: Array<Record<string, any>>, childKeyName: string = 'childResources') => {
  for (let i = 0; i < routers.length; i++) {
    const item = routers[i]
    if (Array.isArray(item[childKeyName]) && item[childKeyName].length) {
      for (let j = 0; j < item[childKeyName].length; j++) {
        const childItem = item[childKeyName][j]
        if (childItem.type === 3 && childItem.enableFlag) {
          router.push(childItem.path)
          return childItem.path
        } else {
          childItem.splice(j, 1)
          getFirstPath(childItem)
        }
      }
    }
  }
}

// 第一次获取接口  第二次读本地
const apiCache = async (key: string, api: Function, params?: any) => {
  if (sessionStorage.getItem(key)) {
    return JSON.parse(sessionStorage.getItem(key) as string)
  } else {
    const { data } = await api(params)
    if (data.code == 0) {
      sessionStorage.setItem(key, JSON.stringify(data.data))
      return data.data
    } else {
      return []
    }
  }
}
// 客服电话
export const getServicePhone = async () => {
  return await apiCache('SERVICE_PHONE', dutyList, { dutyType: 0 })
}

// 判断当前时间是否在指定时间段内
// 入参类似：12:23
export function isWithinTimeRange(startTime: any, endTime: any) {
  // 获取当前时间
  const now = new Date()

  // 创建表示开始时间和结束时间的Date对象
  const start = new Date()
  const end = new Date()

  // 设置开始时间和结束时间的小时和分钟
  const [startHour, startMinute] = startTime.split(':')
  const [endHour, endMinute] = endTime.split(':')
  start.setHours(parseInt(startHour), parseInt(startMinute), 0, 0)
  end.setHours(parseInt(endHour), parseInt(endMinute), 0, 0)

  // 判断当前时间是否在开始时间和结束时间之间
  return now >= start && now <= end
}
