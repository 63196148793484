<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const breadcrumbItems: any = ref([])
const { currentRoute } = useRouter()
watch(
  () => currentRoute.value,
  (route) => {
    const list = route.matched.filter((item) => item.name !== 'index')
    breadcrumbItems.value = list.map((item) => {
      return item.meta.locale
    })
  },
  { immediate: true }
)
</script>

<template>
  <div class="context-breadcrumb">
    <a-breadcrumb>
      <template #separator>
        <icon-right />
      </template>
      <a-breadcrumb-item v-for="(item, index) in breadcrumbItems" :key="index"
        >{{ $t(item) }}
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>
