import { createRouter, createWebHashHistory } from 'vue-router'
import { useMenuStore } from '@/stores/modules/menu'
import { getFirstPath } from '@/utils/commonUtils'
import {authentication} from '@/router/modules/authentication'
const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '',
      name: 'driver-manage',
      meta: { hide: true }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/login/index.vue'),
      meta: { hide: true }
    },
    authentication
  ]
})

router.beforeEach((to: any, from: any, next: any) => {
  if (to.path === '/') {
    const menuStore = useMenuStore()
    const firstPath = getFirstPath(menuStore.menuList)
    if (firstPath) {
      next(firstPath)
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
