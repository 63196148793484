import { defineStore } from "pinia";

interface MenuState {
  menuList: Array<Record<string, any>>
}

export const useMenuStore = defineStore('menu', {
  state: (): MenuState => ({
    menuList: []
  }),
  actions: {
    setMenuList(routerList: Array<Record<string, any>>) {
      this.menuList = routerList
    }
  },
  persist: {
    enabled: true
  }
})