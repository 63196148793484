import DEFAULT_LAYOUT from '../../layout/index.vue'

export const authentication = {
  path: '/home',
  name: 'home',
  // redirect: '/basicData/vehicleType',
  component: DEFAULT_LAYOUT,
  meta: {
    locale: '基础数据',
    icon: 'icon-stamp'
  },
  children: [
    {
      path: '/authentication',
      name: 'authentication',
      // component: () => import('@/views/basic-data/driver-manage.vue'),
      component: () => import('@/views/authentication/home.vue'),
      meta: {
        locale: '我的应用'
      }
    },
   {
      path: '/BusinessOwner',
      name: 'BusinessOwner',
      component: () => import('@/views/authentication/components/BusinessOwner.vue'),
      meta: {
        locale: 'menu.authentication.BusinessOwner',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: '/SmallShipper',
      name: 'SmallShipper',
      component: () => import('@/views/authentication/components/SmallShipper.vue'),
      meta: {
        locale: 'menu.authentication.SmallShipper',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: '/commonCarrier',
      name: 'commonCarrier',
      component: () => import('@/views/authentication/components/commonCarrier.vue'),
      meta: {
        locale: 'menu.authentication.commonCarrier',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
    {
      path: '/VehicleLeader',
      name: 'VehicleLeader',
      component: () => import('@/views/authentication/components/VehicleLeader.vue'),
      meta: {
        locale: 'menu.authentication.VehicleLeader',
        requiresAuth: true,
        roles: ['*'],
        hideInMenu: true,
      },
    },
  ]
}
