
export default {
  /***** 全局通用 *****/
  filter: '更多筛选',
  optional: '操作',
  resetting: '重置',
  review: '审核',

  'user.type': '会员类型',
  'role.name': '角色名称',
  status: '状态',
  'steps.next': '下一步',
  'steps.back': '上一步',
  cancel: '取消',
  system: '系统',
  'operating.system': '操作系统',
  edit: '编辑',
  disable: '禁用',
  enable: '启用',
  noLimit: '不限',

  years: '年',

  'step.second.form.resourceIdList.empty': '功能选择不能为空',
  'function.selection': '功能选择',
  'user.type.placeholder': '请选择会员类型',
  'role.name.placeholder': '请输入角色名称',
  'role.status.placeholder': '请选择角色状态',
  'role.name.required': '角色名称是必填项',
  'role.type.required': '会员类型是必填项',
  'role.name.max20': '角色名称不能超过20个字符',
  'system.placeholder': '请选择系统',

  'create.role': '新建角色',
  'edit.role': '编辑角色',
  'role.name.set': '角色名称设置',
  'use.power': '功能权限',
  'data.power': '数据权限',
  'base.power.name': '基础权限名称',
  'advanced.power.name': '高级权限名称',

  /***** 资源 *****/
  'resource.name': '资源名称',
  'resource.name.placeholder': '请输入资源名称或关键字',
  'resource.url.placeholder': '请输入资源URL',
  'resource.create': '新建资源',
  'resource.type': '资源类型',
  'resource.code': 'Code',
  'resource.sup': '上级资源',
  'affi.system': '所属系统',
  'permissions.role.create': '新建企业角色',
  'permissions.advanced.create': '新建高级权限组',

  /***** 菜单 *****/
  'menu.platform.devops': '平台运维管理',
  'menu.platform.resource': '资源管理',

  'menu.platform.permissions': '权限基础配置',
  'menu.platform.permissions.base': '会员基础权限',
  'menu.platform.permissions.advanced': '会员高级权限',
  'menu.platform.permissions.role': '企业预置角色',
  'duplicateName': '名称不允许重复，请修改',

  'menu.userMemberManage.devops': '用户 会员管理',
  'menu.userMemberManage.userManage': '用户管理',
  'menu.userMemberManage.memberManage': '会员管理',
  'menu.userMemberManage.cargoOwner': '货主管理',
  'menu.userMemberManage.carrier': '承运商管理',
  'menu.userMemberManage.driver': '司机管理',
  'menu.userMemberManage.consignee': '收货人管理',
  'menu.company.companyManage': '公司管理',
  'menu.company.departmentManage': '部门管理',
  'menu.company.roleManage': '角色管理',
  'menu.company.employeeManage': '员工管理',
  'menu.waybill.waybillmanage': '运单管理',
  'menu.waybill.landwaybill': '陆运运单',
  'menu.waybill.landwaybillDetail': '陆运运单详情',

  /***** 登录 *****/
  login: '立即登录',
  remember: '记住密码',
  validate: '图形验证码',
  'validate.placeholder': '请输入图形验证码',
  'validate.errMsg': '请输入图形验证码',
  'userAccount.placeholder': '请输入用户名',
  'userAccount.errMsg': '请输入用户名',
  'password.placeholder': '请输入密码',
  'password.errMsg': '请输入密码',
  'role.placeholder': '请选择登录身份',
  'role.errMsg': '请选择登录身份',
  accountLogin: '手机/邮箱/用户名',
  'accountLogin.placeholder': '手机/邮箱/用户名是必填项',
  passwordInput: '请输入密码',
  oldPasswordInput: '请输入旧密码',
  newPasswordInput: '请输入新密码',
  'passwordInput.placeholder': '密码是必填项',
  'user.phone': '请输入手机号',
  'user.phone.placeholder': '手机号是必填项',
  'user.name': '请输入姓名',

  'user.code': '请输入验证码',
  'user.code.placeholder': '验证码是必填项',
  qrcodeLogin: '登录我找车',
  pleaseUse: '请使用',
  carApp: '我找车 APP',
  nextStep: '下一步',
  portalEnd: '门户端',
  smsSuccess: '短信发送成功',
  reSendSMS: '重新发送',
  platformName: '我找车数字物流',
  automaticLogon: '自动登录',
  forgetPass: '忘记密码',
  register: '快速注册',
  nameRequired: '姓名是必填项',
  phoneRequired: '手机号是必填项',
  phoneSuccess: '请输入正确的手机号',
  codeSuccess: '请输入正确的验证码',
  'reset.password': '重置密码',
  'verify.phone': '验证手机',
  setPassword: '设置密码',
  setSuccess: '设置成功',
  passwordSuccess: '密码规则为8-20位字符;需包含大小写字母+数字+特殊符号',
  refreshQRImg: '刷新二维码',
  setPasswordSuccess: '重置密码成功，请登录',
  registerSuccess: '注册成功，请登录',
  reinspectionPassword: '请检查再次输入的密码',

  'menu.platform.baseComponents': '样式面板',

  /***** 订单规则 *****/
  'order.rule': '订单规则',
  'clock.rule': '打卡规则',
  'transport.rule': '运输规则',
  'warning.rule': '预警规则',
  'sing.rule': '签收规则',
  'freight.settle.rule': '运单结算规则',
  'action.rules.create': '设置规则',
  'laypage.page': '页',
  'laypage.bar': '条',
  'laypage.total': '共',
  'laypage.to': '到',
  'laypage.confirm': '确认',
  //#通用',
  search: '搜索',
  'search.show': '更多筛选',
  'search.hide': '收起筛选',
  reset: '重置',
  export: '导出',
  year: '年份',
  month: '月份',
  date: '日期',
  add: '添加',
  delete: '删除',
  close: '关闭',
  submit: '提交',
  cancle: '取消',
  determine: '确定',
  select: '请选择',
  selectAll: '全部',
  save: '保存',
  title: '标题',
  formopen: '展开',
  putaway: '收起',
  Auditprompt: '正在审核中，请耐心等待',
  //#栏目管理',
  site: '站点',
  column: '栏目',
  columnName: '栏目名称',
  cloumPla: '请输入栏目名称',
  visitUrl: '访问地址',
  visitUrlPla: '请输入访问地址',
  //#站点设置',
  siteName: '站点名称',
  systemSet: '系统设置',
  ownerAppQRCode: '货主端APP二维码',
  ownerAppQRCodeAlt: '货主端APP二维码',
  uploadOwnerAppQRCode: '上传货主端APP二维码',
  driverAppQRCode: '司机端APP二维码',
  driverAppQRCodeAlt: '司机端APP二维码',
  uploadDriverAppQRCode: '上传司机端APP二维码',
  siteDes: '站点描述',
  address: '地址',
  phone: '电话',
  email: '邮箱',
  keywords: '关键字',
  copyright: '版权信息',
  recordNo: '备案号',
  settingPla: '多个关键字,请使用英文(,)号分隔',
  //#内容管理',
  subcolumn: '子栏目',
  coverImg: '封面',
  uploadImage: '上传图片',
  advisetitle1: '推荐上传尺寸 : 宽270px  高175px',
  advisetitle2: '推荐上传尺寸 : 宽96px  高96px',
  advisetitle3: '推荐上传尺寸 : 宽430px  高330px',
  advisetitle4: '推荐上传尺寸 : 宽245px  高166px',
  uploading: '上传中',
  titlePla: '请输入标题',
  abstract: '摘要',
  abstractpla: '请输入文章摘要',
  content: '内容',
  //#合作伙伴',
  partners: '合作伙伴',
  partnerName: '伙伴名称',
  partnerNamePla: '请输入伙伴名称',
  picture: '图片',
  reLoad: '重新上传图片',
  linkUrl: '链接地址',
  linkUrlPla: '点击图片所跳转的地址',
  rotationchart: '轮播图',
  //#轮播图设置',
  pictureTitle: '图片标题',
  uploadVideo: '上传视频',
  advise5: '推荐上传尺寸   宽1920px  高660px',
  //#增值服务',
  companyName: '公司名称',
  isdealt: '是否处理',
  name: '名称',
  dealt: '处理',
  Processed: '已处理',
  Untreated: '未处理',
  //#路线库 路段管理 待审核路线',
  routeName: '路线名称',
  startaddress: '起点地址',
  endaddress: '终点地址',
  state: '状态',
  newroute: '新建路线',
  routedetail: '路线详情',
  selfbuiltplatform: '平台自建',
  ownersharing: '货主共享',
  carriersharing: '承运商共享',
  largetruck: '大型货车',
  minivans: '小型货车',
  flatbedtruck: '平板车',
  normalroute: '正常路线',
  timelimitedroute: '限时路线',
  avoidanceroute: '避让路线',
  prohibitedroute: '禁止路线',
  automobiletransportation: '汽运',
  railwaytransportation: '铁运',
  airtransport: '空运',
  shipping: '船运',
  notshared: '未共享',
  applyingforsharing: '申请共享中',
  applyingforadjustment: '申请调整中',
  rejected: '已拒绝',
  passed: '已通过',
  rescinded: '已撤销',
  transportway: '运输方式',
  carmodel: '车型',
  openDate: '开放时段',
  pressnavigation: '按导航走',
  preferentialadoption: '优先采纳',
  startOpenDate: '起点开放时段',
  terminalOpenDate: '终点开放时段',
  section: '路段',
  addsection: '添加路段',
  serialnumber: '序号',
  type: '类型',
  operation: '操作',
  routePlan: '路线轨迹规划',
  deletereason: '删除原因',
  routeinfo: '路线信息',
  trackname: '轨迹名称',
  createRoute: '生成路线',
  clearwaypoint: '清除途经点',
  viewtracks: '查看轨迹',
  detail: '详情',
  yes: '是',
  no: '否',
  shared: '已共享',
  routeType: '路段类型',
  region: '区域',
  locationfind: '位置查找',
  agree: '同意',
  pass: '通过',
  reject: '拒绝',
  reason: '原因',
  normal: '正常',
  limitedtime: '限时',
  avoid: '避让',
  forbid: '禁止',
  //#运单列表',
  sale: '销售',
  purchase: '采购',
  allocation: '调拨',
  baseinfo: '基本信息',
  conductor: '车长 m/米 长*宽*高',
  carrierlicenseplatenumber: '承运车牌号',
  orderNoofissuingparty: '发布方订单编号',
  orderamountofissuer: '发布方订单金额',
  publisherenterprisename: '发布方企业名称',
  issuersenterprisetaxpayeridentificationnumber: '发布方企业纳税人识别号',
  issuingmethod: '发单方式',
  issuingtime: '发单时间',
  freightamountofgoods: '货物运费金额',
  nameofserviceproviderowner: '服务方(车主)姓名',
  orderreceivingmethod: '接单方式',
  nameofgoods: '货物名称',
  cargovolumeM3: '货物体积(方)',
  cargoweightTT: '货物重量(t/吨)',
  cargounit: '货物单位',
  starttransportationtime: '起始运输时间',
  endtransportationtime: '结束运输时间',
  distance: '路程',
  orderreceivetime: '接单时间',
  orderendtime: '订单结束时间',
  administrativedivisioncodeoftheplaceofdeparture: '起运地行政区划代码',
  administrativedivisioncodeofarrivalplace: '到达地行政区划代码',
  loadingandunloadingmode: '装卸方式',
  detailedaddressoforigin: '始发地详细地址',
  detailedaddressofdestination: '目的地详细地址',
  warehousereceipt: '入库单',
  billoflading: '提货单',
  photosofonsiteunloading: '现场卸货照片',
  onsiteloadingphotos: '现场装货照片',
  nameofpayee: '收款人姓名',
  bankcardnumber: '银行卡号',
  bankjournal: '银行流水',
  paymentmethod: '支付方式',
  paymentvoucher: '支付凭证',
  locus: '轨迹',
  //#多品类管理',
  categorycode: '品类编码',
  categoryname: '品类名称',
  grant: '授权/解除',
  newcategory: '新建品类',
  categoryremarks: '品类备注',
  categoryremarksPla: '请输入品类备注',
  fieldproperties: '字段属性',
  newfield: '新增字段',
  fieldname: '字段名',
  fieldnamePla: '只能输入字母、数字、下划线',
  chineseName: '中文名称',
  chineseNamePla: '请输入中文名称',
  fieldtype: '字段类型',
  format: '格式',
  formatPla: '输入编码，下拉内容请在数据字典维护',
  sort: '排序',
  sortPla: '请输入排序顺序',
  defaultValue: '默认值',
  defaultValuePla: '请输入默认值',
  required: '是否必填',
  display: '是否显示',
  width: '宽度',
  widthPla: '请输入宽度',
  fieldLength: '字段长度',
  fieldLengthPla: '请输入字段长度',
  authorized: '已授权',
  grantCompanyNamePla: '请输入企业名称',
  authorize: '授权',
  cancelauthorization: '解除授权',
  Unauthorized: '未授权',
  ungrantCompanyNamePla: '请输入企业名称',
  textbox: '文本框',
  dropdownbox: '下拉框',
  datebox: '日期框',
  timeframe: '时间框',
  valuebox: '数值框',
  //#签收预警',
  alertname: '预警名称',
  alertnamePla: '请输入预警名称',
  open: '开启',
  routenotphotographed: '途径未拍照',
  incompletetrack: '轨迹不完整',
  retentiontime: '滞留时间(分钟)',
  //#货主预警',
  offlinetime: '离线时长(分钟)',
  offlinewarn: '离线预警',
  lengthofstay: '停留时长(分钟)',
  staywarn: '停留预警',
  sendMessage: '发送站内消息(分钟)',
  notPhotograph: '未拍照预警',
  //#竞价确认策略',
  policyname: '策略名称',
  policynamePla: '请输入策略名称',
  confirmednumber: '确认人数',
  points: '扣点',
  taxdeduction: '扣税',
  biddingperiod: '竞价时间段(分钟)',
  //#自动签收',
  hour: '小时',
  minutes: '分钟',
  executeonce: '执行一次',
  repeatexecution: '重复执行',
  starttime: '开始时间',
  driversignsPla: '司机签收后几分钟开始提醒',
  planType: '计划类型',
  executionfrequency: '执行频率',
  duration: '持续时间(分钟)',
  describe: '描述',
  //# 车辆变更',
  carnumber: '车牌号',
  carnumberPla: '输入车牌号',
  carowner: '车主',
  carownerPla: '输入车主姓名',
  carrycapacity: '载重',
  carrycapacityPla: '输入载重量',
  auditstatus: '审核状态',
  audit: '审核',
  yellowcard: '黄牌',
  bluecard: '蓝牌',
  approved: '审核通过',
  pendingapproval: '待审核',
  auditfailed: '审核失败',
  nameofnewcarowner: '新车主姓名',
  newcarmaintelephone: '新车主电话',
  nameoforiginalowner: '原车主姓名',
  telephonenumberoforiginalowner: '原车主电话',
  pictureinfo: '图片信息',
  roadtransportpermit: '道路运输许可证',
  vehiclelicense: '车辆行驶证',
  frontofvehicle: '车辆正面',
  vehicleside: '车辆侧面',
  reviewcomments: '审核意见',
  reviewcommentsPla: '请输入审核意见',
  reviewedreturned: '审核退回',
  cancelapproval: '取消审核',
  nameofvehicleowner: '车主姓名',
  location: '所在地区',
  //#车辆列表',
  businessname: '业户名称',
  businessnamePla: '输入业户名称',
  nameofreviewer: '审核人姓名',
  endtime: '结束时间',
  modify: '修改',
  task: '任务',
  lessee: '承租人',
  carlength: '车辆长度',
  carWigth: '车辆宽度',
  carHeigth: '车辆高度',
  certificateinfo: '证件信息',
  issuingauthority: '发证机关',
  licenseRegisterTime: '行驶证注册日期',
  dateissue: '发证日期',
  everyone: '所有人',
  useNaturel: '使用性质',
  vehiclebrand: '车辆品牌',
  vehicleNumber: '车辆识别代码',
  roadTransportCertificateNo: '道路运输证号',
  beexecuted: '待执行',
  intransit: '运输中',
  completed: '已完成',
  added: '已添加',
  beconfirmed: '待确认',
  Notuploaded: '未上传',
  Uploaded: '已上传',
  gasoline: '汽油',
  dieseloil: '柴油',
  electric: '电',
  naturalgas: '天然气',
  Hybrid: '混合动力',
  other: '其他',
  Licenseplatecolor: '车牌颜色',
  carTypeName: '车辆类型名称',
  maximumpayload: '最大载重',
  Totalmass: '总质量(kg)',
  //#客户反馈',
  feedbackperson: '反馈人',
  query: '查询',
  noreply: '未回复',
  replied: '已回复',
  reply: '回复',
  see: '查看',
  feedbackinfo: '反馈信息',
  customerreply: '客服回复',
  //#物料管理',
  materialname: '物料名称',
  typename: '类型名称',
  typenamePla: '请输入类型名称',
  materialtype: '物料类型',
  materialtypePla: '请选择物料类型',
  GSmaterialtype: 'GS物料类型',
  GSmaterialtypePla: '请选择GS物料类型',
  materialNo: '物料编号',
  materialnamePla: '请输入物料名称',
  //#车辆品牌管理',
  brandname: '品牌名称',
  brandnamePla: '请输入品牌名称',
  branddescription: '品牌描述',
  branddescriptionPla: '请输入品牌描述',
  //#物类管理',
  categorypicture: '物类图片',
  uploadtips: '点击上传，或将文件拖拽到此处',
  successrender: '上传成功后渲染',
  superior: '所属上级',
  superiorPla: '请选择所属上级',
  //#电子锁',
  passwordlockID: '密码锁ID',
  generalpassword: '常规密码',
  alternatepassword: '备用密码',
  indexgetpassword: '索引获取密码',
  addgroup: '添加分组',
  addlock: '添加锁',
  importlock: '导入锁',
  groupname: '分组名称',
  groupnamePla: '请输入分组名称',
  passwordlockIDPla: '请输入密码锁ID',
  file: '文件',
  uploadFile: '上传文件',
  grouppasswordindex: '组密码索引',
  generalpasswordindex: '常规密码索引',
  alternatepasswordindex: '备用密码索引',
  passwordset: '密码集合',
  alternatepasswordindexPla: '请输入1-10000的索引号',
  around: '前后',
  oneTotenPla: '请填写0-10的数',
  posi: '位',
  obtain: '获取',
  //#船舶列表',
  shipowner: '船主',
  shipname: '船舶名称',
  shipVolume: '船舶容积',
  shipNumberId: '船讯网ID',
  watertransportpermit: '水路运输许可证',
  shipLength: '船舶长度',
  shipWidth: '船舶宽度',
  shipHeight: '船舶高度',
  drivingLicense: '船舶驾驶证',
  shipsideView: '船舶侧面图',
  //#船舶变更',
  applicantname: '申请人姓名',
  applicantphone: '申请人电话',
  shipid: '船号id',
  newshipowner: '新船主',
  newshipownerphone: '新船主电话',
  oldshipowner: '老船主',
  oldshipownerphone: '老船主电话',
  vesselID: '船舶ID',
  //#超时未运输',
  advanceshipmentNo: '预运单号',
  fromUserAddress: '发货人地址',
  toUserAddress: '收货人地址',
  drivername: '司机名称',
  carriername: '承运商名称',
  stopwaybill: '终止运单',
  stoptransport: '暂停运输',
  currentlocation: '当前位置',
  logisticsinfo: '物流信息',
  exceptioninfo: '异常信息',
  transferinfo: '换车信息',
  dispatchdetails: '派车详情',
  maptrack: '地图轨迹',
  transportevaluation: '运输评价',
  confirmreceipt: '确认签收',
  dispatchedcar: '已派车',
  closed: '已关闭',
  notsigned: '未签收',
  endtransport: '终止运输',
  //#线性一览表',
  bigwaybillNo: '大单号',
  littlewaybillNo: '小单号',
  dispatchCarNo: '派车单号',
  shipper: '发货商',
  receiver: '收货商',
  wayBillType: '运单类型',
  selectwayBillType: '选择运单类型',
  signstatus: '签收状态',
  carriercompany: '承运公司',
  carrier: '承运人',
  transporunitprice: '运输单价',
  freightvolume: '运量',
  process: '进程',
  abnormalposition: '异常定位',
  sign: '签收',
  transportfile: '运输档案',
  taskdetail: '任务明细',
  transportconfirm: '运输确认',
  signin: '入场签到',
  loadphoto: '装货拍照',
  exfactorypounds: '出厂磅单',
  starttransport: '开始运输',
  arrivalnotice: '到货通知',
  deliveryphoto: '送达拍照',
  arrivalconfirm: '到货确认',
  ownersigned: '货主签收',
  linearTips: '蚂蚁线百分比表示实际里程与预设里程的占比',
  weighing: '过磅量',
  driverSignInWeight: '司机签收量',
  confirmsignedquantity: '确认签收量',
  weighingphotos: '过磅照片',
  carrierbusiness: '承运商',
  shipmentplace: '发货地',
  receiptplace: '收货地',
  nodata: '无数据',
  logisticstracking: '物流跟踪',
  presetline: '预设线路',
  beidouline: '北斗线路',
  eagleeyeline: '鹰眼线路',
  trackplayback: '轨迹回放',
  customerName: '客户名称',
  nameofshippingunit: '发货单位名称',
  nameofreceivingunit: '收货单位名称',
  starttransporttime: '开始运输时间',
  endtransporttime: '结束运输时间',
  driverphone: '司机电话',
  dispatchtime: '派车时间',
  totalwaybill: '运单总数',
  accruedamount: '预提量',
  driverSignIntime: '司机签收时间',
  suttle: '过磅量',
  ownersignedweight: '货主签收量',
  inFactoryTime: '入场时间',
  oneWeightTime: '一次过磅时间',
  twoWeightTime: '二次过磅时间',
  outFactoryTime: '出场时间',
  gross: '毛量',
  tare: '皮重',
  signInWeight: '签收量',
  remainingquantity: '剩余数量',
  signinfo: '签收信息',
  thirdPartyNo: '客商订单号',
  thirdDispatchCarNo: '客商派车单号',
  closedsigned: '关闭(签收)人',
  adjuster: '调量人',
  closedsignedreason: '关闭(签收)原因',
  adjustmentreason: '调量原因',
  platformreconcilor: '平台对账人',
  ownersreconcilor: '货主对账人',
  ownerreconciliationtime: '货主对账时间',
  carrierreconcilor: '承运商对账人',
  carrierreconciliationtime: '承运商对账时间',
  dispatchcarperson: '派车人',
  Saleswaybill: '销售运单',
  Purchasewaybill: '采购运单',
  Transferwaybill: '调拨运单',
  distances: '距离',
  //#货主列表',
  realname: '真实姓名',
  phonenumber: '手机号码',
  IDnumber: '身份证号',
  reviewername: '审核人姓名',
  authorizationstatus: '授权状态',
  nopicture: '暂无图片',
  updatePower: '更新角色',
  selectPower: '查看角色',
  selectAllPower: '查看所有权限',
  secretKey: '授权密钥管理',
  openadmin: '管理员授权',
  closeadmin: '取消管理员',
  refreshpassword: '重置密码',
  denyauthorization: '拒绝授权',
  departmentName: '部门',
  lastVisitTime: '最近访问时间',
  province: '所在省份',
  city: '所在城市',
  district: '所在地区',
  frontIDcard: '身份证正面',
  selectrole: '请选择角色',
  backIDcard: '身份证反面',
  viewpower: '查看权限',
  authorizationkey: '授权密钥',
  generatekey: '生成密钥',
  //#承运商列表',
  disablenetworkfreight: '禁用网络货运',
  enablenetworkfreight: '启用网络货运',
  mydriver: '我的司机',
  merchantaccount: '商户账号',
  carrierType: '承运商类型',
  businessNum: '营业执照编号',
  roadtransportpermitNo: '道路运输许可证编号',
  businessvalidTime: '营业执照有效时间',
  businessvalidStatus: '营业执照有效期状态',
  recordCard: '备案证',
  businessLicense: '营业执照',
  powerAttorney: '委托书',
  legalPerson: '法人',
  legalPersonIDCard: '法人身份证号码',
  legalPersonIDCardfront: '法人身份证正面',
  createTime: '创建时间',
  modifytime: '修改时间',
  sealhorizontaltextcontent: '印章横文内容',
  sealhorizontaltextcontentPla:
    '生成印章中的横向文内容 可设置0-8个字，企业名称超出25个字后，不支持设置横向文 一般是指的XX专用章，如合同专用章、财务专用章等',
  seallastquartercontent: '印章下弦文内容',
  seallastquartercontentPla:
    '生成印章中的下弦文内容 可设置0-20个字，企业企业名称超出25个字后，不支持设置下弦文',
  sealtype: '印章类型',
  sealcolor: '印章颜色',
  sealphoto: '印章图片',
  sealphotoAlt: '暂无签章图片',
  individualcarrier: '个人承运商',
  generaltaxpayer: '一般纳税人',
  smallbusiness: '小规模企业',
  standardseal: '标准公章',
  ovalseal: '椭圆形印章',
  roundpentagram: '圆形无五角星章',
  //#司机列表',
  selectCarrier: '我的承运商',
  driver: '司机',
  crew: '船员',
  drivertype: '司机类型',
  driverlicensepic: '驾驶证图片',
  vehicleowner: '车辆所有人',
  carriageleader: '承运车长',
  carriercarnum: '承运车牌号',
  attachedvehicle: '是否挂靠车辆',
  actualowner: '实际所有人',
  ownercontact: '所有人联系方式',
  carriermodel: '承运车型',
  vehiclelicensepic: '行驶证照片',
  vehiclephotos: '车辆照片',
  carryingcapacity: '承运载重',
  affiliationstatement: '挂靠申明',
  driverIDcard: '司机身份证',
  publisheruniqueID: '发布方唯一标识',
  driverlicensephoto: '驾驶证照片',
  driverlicensenumber: '驾驶证件号码',
  accountholdername: '开户人姓名',
  accountholderIDnumber: '开户人身份证号',
  bankdeposit: '开户银行',
  serviceregistertime: '服务方注册时间',
  affiliationagreement: '挂靠协议',
  roletype: '角色类型',
  regionalismcode: '行政区划代码',
  traveldocumentnumber: '行驶证件号码',
  permitteddrivingmodel: '准驾车型',
  licenseissuingauthority: '驾驶证发证机关',
  driverValidEndTime: '驾驶证有效期',
  driverValidStartTime: '驾驶证领证日期',
  qualificationNum: '从业资格证书',
  qualificationphoto: '从业资格照片',
  //#变更手机号',
  selectroletype: '选择角色类型',
  carrierP: '承运商',
  newphone: '新手机号',
  oldPhone: '原手机号',
  //#公司列表',
  companyNamePla: '请输入公司名称',
  synchro: '同步',
  auditcertification: '审核认证',
  refuseauthentication: '拒绝认证',
  certified: '已认证',
  undercertification: '认证中',
  authenticationfailed: '认证失败',
  becertified: '待认证',
  viewQRcode: '查看二维码',
  companyshortName: '公司简称',
  companyshortNamePla: '请输入公司简称',
  companyType: '公司类型',
  selectcompanytype: '请选择公司类型',
  AlQaeda: '基地组织',
  selectAlQaeda: '请选择基地组织',
  businesslicenseNo: '营业执照号码',
  businesslicenseNoPla: '请输入营业执照号码',
  legalPersonname: '法人姓名',
  legalPersonnamePla: '请输入法人姓名',
  legalPersoncertificateNo: '法人证件号',
  legalPersoncertificateNoPla: '请输入法人证件号',
  handledby: '经办人',
  handledbyPla: '请输入经办人',
  handlerIDNo: '经办人证件号',
  handlerIDNoPla: '请输入经办人证件号',
  handlerphone: '经办人电话',
  handlerphonePla: '请输入经办人电话',
  affiliatedcompany: '所属公司',
  certificatevalidity: '证件有效期',
  certificatevalidityPla: '请选择营业执照有效期',
  forwardingfirm: '托运公司',
  pilotenterprise: '是否试点企业',
  whetherbelongsCNBM: '是否归属中建材',
  templateLimit: '模板限量',
  templateLimitPla: '请输入模板限量',
  subTemplateLimit: '子账号模版限量',
  subTemplateLimitPla: '请输入子账号模版限量',
  companydescription: '公司描述',
  companydescriptionPla: '请输入公司描述',
  companylogo: '公司LOGO',
  businesslicensepicture: '营业执照图片',
  citydistrict: '所在市/区',
  county: '所在县',
  detailaddress: '详细地址',
  detailaddressPla: '请输入详细地址',
  enterpriseuseraccount: '企业用户账号',
  legalrepresentative: '法定代表人',
  companyaddress: '公司地址',
  businessscope: '经营范围',
  enterprisetype: '企业类型',
  enterprisenametaxpayername: '企业名称(纳税人名称)',
  registerservicepublishtime: '注册为服务发布方时间',
  enterprisetaxpayeridentificationnumber: '企业纳税人识别号',
  enterprisephonenumber: '企业手机电话号码',
  dateIncorporation: '成立日期',
  regionalismcodecity: '行政区划代码(市级)',
  registeredcapital: '注册资本',
  competenttaxauthority: '主管税务机关',
  rejectReason: '拒绝原因',
  rejectReasonPla: '请输入拒绝原因',
  group: '集团',
  company: '公司',
  subsidiary: '子公司',
  branchoffice: '分公司',
  Sealmanagement: '印章管理',
  notSealTips: '暂无印章',
  reasonPla: '请写明取消授权原因',
  isUseEsign: '是否启用电子签章',
  //#公司同步配置',
  enterprisename: '企业名称',
  keywordPla: '输入关键字',
  ordernotreturned: '订单不回传',
  orderreturn: '订单回传',
  balanceordersynchronization: '余额订单同步',
  addconfiguration: '添加配置',
  openclose: '开启|关闭',
  yesno: '是|否',
  querybytimeperiod: '按时间段查询',
  querybymonth: '按月份查询',
  returnstatus: '回传状态',
  dataPage: '数据是否分页',
  closeDispatch: '允许关闭派车单',
  customerSync: '允许客户同步',
  dispatchSync: '派车单同步',
  enclosureSync: '围栏信息同步',
  thirdCloseDispatch: '第三方关闭派车单',
  saleWeightSync: '销售余额模式',
  purchaseWeightSync: '采购余额模式',
  isShort: '是否开启短倒业务',
  lineUpEnclosure: '园区排队围栏',
  relatedBusiness: '关联业务',
  timeQuery: '时间查询条件',
  moreLoadUnload: '是否开启多装多卸',
  moreTransport: '是否开启多式联运',
  useEsign: '是否开启电子签章',
  //#统计分析',
  transportmanagement: '运输管理',
  vehiclestransit: '在途车辆',
  transportweight: '运输重量',
  onlinevehicle: '在线车辆',
  offlinevehicle: '离线车辆',
  settleinfo: '入驻信息',
  settledshipper: '入驻托运人',
  residentdriver: '入驻司机',
  inboundcarrier: '入驻承运商',
  parkedvehicle: '入驻车辆',
  todolist: '待办事项',
  vehiclebereviewed: '待审核车辆',
  driverbereviewed: '待审核司机',
  carrierbereviewed: '待审核承运商',
  driverinfo: '司机信息',
  activedriver: '活跃司机',
  commondriver: '常用司机',
  commonvehicles: '常用车辆',
  waybillstatistics: '运单统计',
  dispatchstatistics: '派单统计',
  vehiclesstatistics: '车辆统计',
  driverstatistics: '司机统计',
  paystatistics: '支付统计',
  newwaybillquantitytoday: '今日新增运单数量',
  deliverycompletedtoday: '今日完成派单',
  newdistributiontoday: '今日新增派单',
  numberofnewvehiclestoday: '今日新增车辆数量',
  numberofnewdriverstoday: '今日新增司机数量',
  freightsettlementamounttoday: '今日运费结算金额',
  //#合同管理',
  signing: '签订中',
  signedP: '已签订',
  signingfailed: '签订失败',
  newcontract: '新建合同',
  uploadcontract: '上传合同',
  contractvalidtime: '合同有效时间',
  selectcontracttype: '请选择合同类型',
  legalapproval: '法务审批',
  customerBusinessType: '客户企业类型',
  cooperation: '合作情况',
  registeredCapitalOfClients: '客户注册资本',
  grossProfitCalculation: '毛利测算',
  contractsNum: '合同份数',
  customerContact: '客户联系人',
  summaryOfContractSigning: '签约综述',
  contactMobile: '客户联系电话',
  settlementinfo: '结算信息',
  packPrice: '货物单价',
  freightrate: '货物运价',
  goodsNum: '货物数',
  contractcontent: '合同内容',
  enclosure: '附件',
  upload: '上传',
  enclosuretip: '(请上传pdf文件)',
  //#合同模板',
  templatename: '模板名称',
  templatenamePla: '输入模板名称',
  templateType: '模板类型',
  templateApplication: '模板应用类型',
  templateTypePla: '请选择模板类型',
  newbuild: '新建',
  publish: '发布',
  failure: '失效',
  templateinfo: '模板信息',
  templateCode: '模板编号',
  templateCodePla: '系统自动生成',
  businessType: '业务类型',
  selectbusinessType: '请选择业务类型',
  organization: '所属组织机构',
  carrierenterprise: '所属承运商企业',
  contractvalidity: '合同有效期',
  templatecontent: '模板内容',
  platformownercontract: '平台-货主合同',
  platformcarriercontract: '平台-承运商合同',
  platformdrivercontract: '平台-司机合同',
  ownercarrier: '货主-承运商',
  carrierplatformtransfernetworkfreight: '承运商-平台（转网络货运）',
  affiliationagreementP: '挂靠协议',
  wnerstatement: '车主申明',
  transportationcontract: '运输合同',
  servicecontract: '服务合同',
  agreememt: '协议',
  networkFreight: '网络货运',
  nonnetworkfreight: '非网络货运',
  Validincurrentyear: '当年有效',
  halfayear: '半年',
  oneyear: '一年',
  twoyear: '两年',
  threeyear: '三年',
  firstquarter: '一季度',
  //#运输合同',
  contractCode: '合同编号',
  contractCodePla: '请输入合同编号',
  contractName: '合同名称',
  contractNamePla: '请输入合同名称',
  firstParty: '甲方',
  firstPartyPla: '请输入甲方名称',
  partyB: '乙方',
  partyBPla: '请输入乙方名称',
  signingMethod: '签约方式',
  contractType: '合同类型',
  transportType: '运输方式',
  previewcontract: '预览合同',
  generalcontracting: '总包',
  selfsupport: '自营',
  platformbusines: '平台业务',
  effect: '生效',
  online: '线上',
  offline: '线下',
  platformdriver: '平台-司机',
  platformcarrier: '平台-承运商',
  platformowner: '平台-货主',
  landcarriage: '陆运',
  watercarriage: '水运',
  //#服务合同',
  signingtime: '签约时间',
  signingtimePla: '请输入签约时间',
  Initiatesigning: '发起签约',
  PartyAsigns: '甲方签约',
  PartyACancellation: '甲方撤销',
  PartyBsigns: '乙方签约',
  PartyBCancellation: '乙方撤销',
  duedate: '到期日',
  duedatePla: '请输入到期日',
  //#日志挂管理',
  operatorTime: '操作时间',
  operatorTimePla: '请选择操作时间',
  //#知识库管理',
  theme: '主题',
  //#承运商派车情况',
  startdate: '开始日期',
  enddate: '结束日期',
  //#派车单统计',
  summaryinfo: '汇总信息',
  dispatchcarnumber: '派车单的车数',
  orders: '单',
  amountofvehicledispatchslip: '派车单预提量',
  totalsignedestimated: '签收总量（估）',
  totalfreightestimated: '总运费(估)',
  totalplannedmileage: '规划总里程',
  actualtotalmileage: '实际总里程',
  milliontons: '万吨',
  millionkilometers: '万公里',
  notopen: '未开启',
  signed: '已签收',
  abnormalsign: '异常签收',
  //#运单统计',
  wayBillNo: '运单号',
  orderquantity: '订单量',
  ton: '吨',
  transportedvolume: '已运输量',
  intransitvolume: '正在运输量',
  nottransported: '未运输量',
  //#审核统计',
  vehicle: '车辆',
  changevehicle: '变更车辆',
  aship: '船舶',
  //#框架协议管理',
  agreementName: '协议名称',
  agreementNamePla: '请输入协议名称',
  agreementType: '协议类型',
  agreementinfo: '协议信息',
  selectsignway: '请选择签约方式',
  role: '角色',
  agreementcontent: '协议内容',
  owner: '货主',
  consignee: '收货人',
  useragreement: '用户协议',
  privacypolicy: '隐私申明',
  electronicsigning: '电子签约',
  //#结算规则配置',
  batchpaymentconfig: '批量付款配置',
  settlementQuantityRange: '每单结算量调整范围',
  inputPla: '请输入',
  maximumamountperorderreminder: '每单最大金额提醒',
  settlementamountadjustmentconfig: '结算金额调整配置',
  yuan: '元',
  batchsave: '批量保存',
  //#管理员系统设置',
  //#账号管理',
  account: '账号',
  allstate: '所有状态',
  updatebusinessroles: '更新业务角色',
  updaterole: '更新角色',
  viewrole: '查看角色',
  accountPla: '请输入账号',
  namePla: '请输入姓名',
  phonenumberPla: '请输入手机号码',
  emailPla: '请输入邮箱',
  affiliateddepartment: '所属部门',
  remarks: '备注',
  remarksPla: '请输入备注',
  owneraccountsync: '货主账号同步',
  ownerrole: '货主端角色',
  //#部门管理',
  departmentNamePla: '请输入部门名称',
  newadd: '新增',
  //#管理员权限管理',
  catalogue: '目录',
  menu: '菜单',
  button: '按钮',
  permissionName: '权限名称',
  permissionNamePla: '请输入权限名称',
  systemsource: '系统来源',
  sortNo: '排序号',
  sortNoPla: '请输入排序号',
  permissiontype: '权限类型',
  menuurl: '菜单url',
  menuurlPla: '请输入菜单url',
  permissioncode: '权限code',
  permissioncodePla: '请输入权限code',
  menuicon: '菜单图标',
  menuiconPla: '请输入菜单图标',
  uppermenu: '上级菜单',
  //#管理员角色管理',
  authorityallocation: '权限分配',
  dataauthorization: '数据授权',
  valid: '有效',
  invalid: '失效',
  roleName: '角色名',
  roleNamePla: '请输入角色名',
  permissionlist: '权限列表',
  selected: '已选',
  buttonName: '按钮名称',
  //#数据权限定义',
  adddatapermissions: '添加数据权限',
  drop: '下拉框',
  table: '表',
  basicTable: '基础表',
  basicTablePla: '请输入基础表',
  basicTableName: '基础表名称',
  basicTableNamePla: '请输入基础表名称',
  permissionField: '权限字段',
  permissionFieldPla: '请输入权限字段',
  permissionFieldName: '权限字段名称',
  permissionFieldNamePla: '请输入权限字段名称',
  permissionFieldValue: '权限值',
  permissionFieldValuePla: '请输入权限值',
  permissionFieldMode: '权限模式',
  allowaccessbydefault: '默认允许访问',
  accessdisabledbydefault: '默认禁用访问',
  //#数据字典管理',
  dictionarydetails: '字典明细',
  code: '编码',
  codePla: '请输入编码',
  namePlas: '请输入名称',
  describePla: '请输入描述',
  //#意见反馈',
  userName: '用户姓名',
  contactnumber: '联系电话',
  viewdetails: '查看明细',
  read: '已读',
  unread: '未读',
  messagecontent: '留言内容',
  additionalpictures: '附加图片',
  replyContent: '回复内容',
  replyTime: '回复时间',
  replyName: '回复人',
  //#企业管理',
  //#用户管理',
  refreshdeparment: '更新部门',
  //#组织管理',
  applycertification: '申请认证',
  viewseal: '查看印章',
  maintenanceseal: '维护印章',
  red: '红色',
  blue: '蓝色',
  black: '黑色',
  circularsealfivepointedstar: '圆形章-五角星',
  ovalseals: '椭圆章',
  roundnopentagram: '圆形-无五角星',
  sealhorizontalTips:
    '注：生成印章中的横向文内容；可设置0-8个字，企业名称超出25个字后，不支持设置横向文；一般是指的XX专用章，如合同专用章、财务专用章等',
  seallastquarterTips:
    '注：生成印章中的下弦文内容；可设置0-20个字，企业企业名称超出25个字后，不支持设置下弦文；下弦文是指的贵司公章底部一串防伪数字，若没有该参数可不填',
  Exampleofseal: '印章示例',
  //#登录页',
  adminlogin: '管理登录',
  adminaccountPla: '请输入管理员账号',
  adminpasswordPla: '请输入登录密码',
  PlatformManagementSide: '平台管理端',
  //#index.html',
  changepassword: '修改密码',
  signout: '退出',
  indextitle: '我找车智慧物流平台-管理',
  news: '消息',
  todolists: '待办',
  notice: '通知',
  Entermymessage: '进入我的消息',
  Cleartodo: '清空待办',
  emptyNotice: '清空通知',
  Falsealarm: '误报',
  messageTitle: '消息标题',
  messageContent: '消息内容',
  Batchread: '批量已读',
  Falsepositivedescription: '误报说明',
  Selectmultiplefiles: '选择多文件',
  pictureVideoUpload: '图片 / 视频上传',
  filename: '文件名',
  fileSize: '大小',
  Startupload: '开始上传',
  Reupload: '重传',
  //#修改密码',
  originalpassword: '原始密码',
  originalpasswordPla: '请输入原始密码',
  newpassword: '新密码',
  newpasswordPla: '请输入新密码',
  confirmpassword: '确认密码',
  confirmpasswordPla: '请再次输入新密码',
  //#细节优化',
  closeCurrentTab: '关闭当前标签页',
  closeOtherTab: '关闭其它标签页',
  closeAllTab: '关闭全部标签页',
  sideexpansion: '侧边伸缩',
  refresh: '刷新',
  fullScreen: '全屏',
  //#页面lay-reqtext',
  frequencyreqtext: '请设置执行频率',
  durationreqtext: '请设置持续时间',
  confirmednumberreqtext: '请设置人数',
  pointsreqtext: '请设置扣点',
  taxdeductionreqtext: '请设置扣税',
  biddingperiodreqtext: '请设置竞价时间段',
  offlinetimereqtext: '请设置离线时长',
  lengthofstayreqtext: '请设置滞留时间',
  sendMessagereqtext: '请设置发送站内消息',
  //#报警管理',
  Alarmstarttime: '报警开始时间',
  Alarmendtime: '报警结束时间',
  alarmOptionNo: '报警项编号',
  alarmOptionName: '报警项名称',
  Noticedetails: '通知详情',
  Processinglog: '处理日志',
  Realtimealarm: '实时报警',
  Monitoringalarm: '监控报警',
  Normalalarm: '正常报警',
  bigwaybill: '大单',
  littlewaybill: '小单',
  dispatchCarwaybill: '派车单',
  viewPictureVideo: '查看图片/视频',
  mail: '站内信',
  Appmessage: 'app消息',
  shortmessage: '短信',
  emails: '邮件',
  Webmanualprocessing: 'web端手工处理',
  Messagefeedbackprocessing: '消息反馈处理',
  DownloadView: '下载查看',
  Video: '视频',
  //#大屏',
  Alarmoverview: '报警概况',
  Fullplatform: '全平台',
  Numberofalarms: '个报警数',
  Coveragetoday: '今日覆盖',
  Cities: '个城市',
  AllCity: '全国城市/区县',
  produce: '产生',
  Alarms: '个报警',
  Numberofalarmstoday: '今日报警数',
  importantAlarmCount: '今天重点报警类型次数',
  Alarmyearonyear: '报警同比',
  Todayyesterdaytrend: '今日与昨日趋势同比',
  Regionalproportion: '区域占比',
  //#报警级别',
  alarmLevel: '报警级别',
  noticeway: '通知方式',
  noticeLevel: '通知等级',
  //#报警项管理',
  nothing: '无',
  formula: '公式',
  Remotecall: '远程调用',
  exist: '有',
  Alarmtype: '报警类型',
  effectTime: '生效时间',
  effectTimePla: '请选择时间',
  invalidTime: '失效时间',
  monitorValue: '监控值',
  Unit: '单位',
  second: '秒',
  Kilometers: '千米(KM)',
  Multiplematch: '多项匹配',
  Whetherthemessageneedstobeprocessed: '消息是否需处理',
  noticeCustomerService: '通知客服',
  Alarmlevelupgrade: '报警级别升级',
  assignUseRoles: '指定使用角色',
  Initialalarmlevel: '起始报警级别',
  selectAlarmLevel: '请选择报警级别',
  messageTitlePla: '支持通过${变量名}取参数值',
  judgmentRule: '判断规则',
  ruleType: '规则类型',
  ruleContent: '规则内容',
  ruleContentPla:
    '规则类型为公式时，则值为公式；规则类型为远程调用时，则值为URL链接地址',
  //#监控任务',
  monitorStartTime: '监控开始时间',
  monitorEndTime: '监控结束时间',
  monitorStatus: '监控状态',
  Monitoring: '监控中',
  stop: '停止',
  complete: '完成',
  Monitorparameters: '监控参数',
  //#消息推送管理',
  noticeTimeFrom: '通知时间从',
  noticeTimeTo: '通知时间到',
  noticeStatus: '通知状态',
  Notnotified: '未通知',
  Notificationsucceeded: '通知成功',
  Notificationfailed: '通知失败',
  Alarmmessagenumber: '报警消息编号',
  Pushagain: '再次推送',
  //#报警一览表',
  cleanUp: '清除',
  AlarmDetails: '报警详情',
  //#标签栏管理',
  //#指标库管理',
  Indexname: '指标名称',
  IndexnamePla: '输入指标名称',
  indexType: '所属类型',
  indexTypePla: '选择所属类型',
  isEnable: '是否启用',
  selectType: '选择类型',
  //#标签库管理',
  tagName: '标签名称',
  tagtype: '标签类型',
  //#评价申诉处理',
  //#司机评价申诉',
  appealPerson: '申诉人',
  appealStatus: '申诉状态',
  UnderAppeal: '申诉中',
  Agreed: '已同意',
  Tonnageofcargo: '货物吨数',
  ShippingAddress: '发货地址',
  ShipToAddress: '收货地址',
  Contentofownerevaluation: '货主评价内容',
  Viewevaluationdetails: '查看评价详情',
  Carrierevaluationcontent: '承运商评价内容',
  Complainthandlingcontent: '申诉处理内容',
  Handleopinions: '处理意见',
  HandleopinionsPla: '处理意见最多150字',
  Pictureimport: '图片导入',
  Processresults: '处理结果',
  getImageUrl: '获取图片url的值',
  //#承运商评价申诉',
  Carriercomplaintcontent: '承运商申诉内容',
  Processingpictures: '处理图片',
  //#司机评分体系',
  Servicecapability: '服务能力',
  Promisekeepingrecord: '守约记录',
  Participation: '参与度',
  qualifications: '资质',
  Thirdpartyevaluation: '第三方评价',
  ServicecapabilityTips:
    '服务能力是指你在平台为平台的各货主提供的物流运输的服务过程中各节点的作业记录客观评估',
  Servicerecord: '服务记录',
  Servicescenario: '服务场景',
  appointNum: '指派次数',
  bidNum: '竞价次数',
  businessTypeNum: '业务类型种类',
  garbNum: '抢单次数',
  materialTypeNum: '运输物料种类',
  shipperNum: '货主数量',
  times: '次',
  Several: '种',
  numbers: '个',
  PromisekeepingrecordTips:
    '守约记录是指您在抢单竞价后是否按照定实时完成了订单的客观评价',
  ParticipationTips:
    '参与度指您在注册平台后参与平台运输,抢单,竞价等与平台的互动能力',
  qualificationsTips:
    '资质指您注册平台后,各证件是否齐全,证件有效期是否有效的客观评价',
  ThirdpartyevaluationTips:
    '第三方评价指货主,收货人对您运输任务的主观评价,您对有疑义的评价可以提起平台申诉',
  //#灰度管理',
  //#灰度服务管理',
  serverName: '服务名称',
  serverNamePla: '请输入服务名称',
  //#灰度用户管理',
  userType: '用户类型',
  Grayscaleservice: '灰度服务',
  namePhoneNumber: '姓名/手机号码',
  SelectService: '请选择服务',
  //#历史数据查询',
  //#历史数据转热审核列表',
  Batchaudit: '批量审核',
  implement: '执行',

  /****** 登录页 *****/
  username: '用户名',
  password: '密码',
  loginSubmit: '立即登录',
  passLogin: '密码登录',
  codeLogin: '验证码登录',
  codingLogin: '扫码登录',
  sendCaptcha: '发送验证码',
  platformCarrier: '平台承运商端',
  appLogin: 'APP二维码登录',
  platformDesc: '我找车智慧物流平台',
  appLoginDesc: '用我找车APP扫一扫登录账号',


  'menu.authentication': '我的应用',
  'menu.authentication.BusinessOwner': '企业货主认证',
  'menu.authentication.SmallShipper': '小型货主认证',
  'menu.authentication.commonCarrier': '企业承运商认证',
  'menu.authentication.VehicleLeader': '个人承运商认证',
  /****** 运单管理 *****/
  'waybill.all': '全部',
  'waybill.tobeopen': '待开启',
  'waybill.intransit': '运输中',
  'waybill.tobesign': '待签收',
  'waybill.signed': '已签收',
  'waybill.cancelledorder': '已撤单',
  'waybill.tobesettled': '待结算',

  'waybill.contacts': '联系人',
  'waybill.carNo': '车牌号',
  'waybill.dispatchDriver': '运单司机',
  'waybill.routeName': '路线名称',

  'waybill.status.toBeOpen': '待开启',
  'waybill.status.agreedLoaded': '待预约装货',
  'waybill.status.checkInLoaded': '待装货签到',
  'waybill.status.toBeLoaded': '待装货',
  'waybill.status.agreedUnloaded': '待预约卸货',
  'waybill.status.checkInUnloaded': '待卸货签到',
  'waybill.status.toBeUnloaded': '待卸货',
  'waybill.status.toBeSign': '待签收',
  'waybill.status.completed': '已完成',
  'waybill.status.cancelOrder': '已撤单',

  // 陆运运单详情-详情信息
  'waybill.detail': '详情',
  'waybill.detail.loadInfo': '装卸信息',
  'waybill.detail.baseInfo': '基础信息',
  'waybill.detail.ownerName': '货主名称',
  'waybill.detail.dispatchTime': '派车时间',
  'waybill.detail.orderNumber': '订单号',
  'waybill.detail.shippingCompany': '发货单位',
  'waybill.detail.receivingCompany': '收货单位',
  'waybill.detail.transportNoteNumber': '运输计划单号',
  'waybill.detail.inspector': '验货人',
  'waybill.detail.telephone': '联系电话',
  'waybill.detail.remarks': '备注',
  'waybill.detail.transportCondition': '运输要求',
  'waybill.detail.transportRules': '运输规则',
  'waybill.detail.specificRoute': '指定路线',
  'waybill.detail.navigation': '按导航走',
  'waybill.detail.allowRouteAdjustment': '允许路线调整',
  'waybill.detail.reservationOrder': '预约单',
  'waybill.detail.loadReservation': '装货强制预约',
  'waybill.detail.unloadReservation': '卸货强制预约',
  'waybill.detail.loadService': '提供装车',
  'waybill.detail.unloadService': '提供卸车',
  'waybill.detail.invoiceService': '提供发票',
  'waybill.detail.BeidouPosition': '北斗定位设备',
  'waybill.detail.offlineRules': '离线规则',
  'waybill.detail.fixedVehicles': '指定车型',
  'waybill.detail.drivingExperience': '司机驾龄要求',
  'waybill.detail.vehicleAge': '车龄要求',
  'waybill.detail.clockRules': '打卡规则',
  'waybill.detail.clockNode': '打卡节点',
  'waybill.detail.bindingLock': '绑定智能锁',
  'waybill.detail.bindingSeal': '是否要求铅封',
  'waybill.detail.clockInArea': '围栏内打卡',
  'waybill.detail.autoStartTransport': '自动开启运输',
  'waybill.detail.autoArrivalConfirm': '自动到货确认',
  'waybill.detail.autoTimeoutConfirm': '超时自动确认',
  'waybill.detail.albumAvailable': '从相册选照片',
  'waybill.detail.warningRule': '预警规则',
  'waybill.detail.defaultWarning': '默认预警',
  'waybill.detail.stopWarning': '停留预警',
  'waybill.detail.deviateWarning': '偏离预警',
  'waybill.detail.offlineWarning': '离线预警',
  'waybill.detail.poundWarning': '磅单预警',
  'waybill.detail.fenceWarning': '高危围栏预警',
  'waybill.detail.smsObject': '短信对象',
  'waybill.detail.stationObject': '站内对象',
  'waybill.detail.ownerList': '货主列表',
  'waybill.detail.residenceTime': '停留时间',
  'waybill.detail.stayInterval': '停留间隔',
  'waybill.detail.signinRules': '签收规则',
  'waybill.detail.autoReceipt': '自动签收',
  'waybill.detail.confirmedBy': '收货确认人',
  'waybill.detail.inspection': '验货',
  'waybill.detail.pricingAfter': '货到后定价',
  'waybill.detail.convertConfig': '转换配置',
  'waybill.detail.autoSettlement': '自动结算',
  'waybill.detail.freightSettlement': '运费结算',
  'waybill.detail.freightCalcMethod': '运费计算方式',
  'waybill.detail.settlementCountRules': '结算数量规则',
  'waybill.detail.floorRules': '抹零规则',
  'waybill.detail.lossRules': '亏吨规则',
  'waybill.detail.riseRules': '涨吨规则',
  // table
  'waybill.detail.goodsName': '货物名称',
  'waybill.detail.goodsUnit': '货物单位',
  'waybill.detail.takeDeliveryCount': '预提量',
  'waybill.detail.loadCount': '装货量',
  'waybill.detail.loadWeighFirst': '装货一次过磅量',
  'waybill.detail.loadWeighSecond': '装货二次过磅量',
  'waybill.detail.unloadCount': '卸货量',
  'waybill.detail.unloadWeighFirst': '卸货一次过磅量',
  'waybill.detail.unloadWeighSecond': '卸货二次过磅量',
  'waybill.detail.driverSignedCount': '司机签收量',
  'waybill.detail.ownerSignedCount': '货主签收量',
  'waybill.detail.diffCount': '差异量',

  // 运单详情-电子锁信息
  'waybill.lock': '电子锁',

  // 运单详情-铅封信息
  'waybill.seal': '铅封',

  // 运单详情-财务信息
  'waybill.finance': '财务信息',

  // 运单详情页按钮
  'waybill.button.editPayee': '修改收款人',
  'waybill.button.revokeApproval': '撤单审核',
  'waybill.button.remindDriver': '提醒司机',
  'waybill.button.close': '关闭',
  'waybill.button.feeUpload': '费用上传',
  'waybill.button.evaluate': '评价',

  // 运单详情页按钮对应的提示语
  'waybill.message.editPayee': '修改收款人成功！',
  'waybill.message.revokeApproval.agree': '同意该运单撤单！',
  'waybill.message.revokeApproval.refuse': '拒绝该运单撤单！',
  'waybill.message.remindDriver': '提醒成功！',
  'waybill.message.close': '关闭成功！',
  'waybill.message.feeUpload': '费用上传成功！',
}
