import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: (): UserStore => ({
    isAdmin: false,
    companyId: '',
    companyName: '',
    companyIdentity:'',
    headPortrait:'',
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'user',
        storage: localStorage
      }
    ]
  }
})

interface UserStore {
  isAdmin: boolean
  companyId: string
  companyName: string
  companyIdentity: string
  headPortrait:string
}
